import { AssetDoc, UserRole } from '@poem/pam-utils'
import {
  updateListActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/firestore'
import { subscribeToListFactory } from './factories'

export const subscribeToAssetList = subscribeToListFactory<AssetDoc>(
  'assetList',
  'assets',
  updateListActionType.updateAssetList,
  updateUnsubscribeFnActionType.updateAssetListUnsubscribeFn,
  async (collectionRef, getState) => {
    let user

    while (!user) {
      user = getState().user.data
      await new Promise(resolve => {
        setTimeout(() => resolve(), 1000)
      })
    }

    const { role, email } = user

    if (role === UserRole.viewer) {
      return collectionRef.where('currentCustodian', '==', email)
    } else {
      return collectionRef
    }
  }
)
