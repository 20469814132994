import { AssetDoc, AssetIndexDoc, UserRole } from '@poem/pam-utils'
import { message, PageHeader, Skeleton, Tag } from 'antd'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useCompanyRef, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { errors, getAssetMetaFromAssetDoc, isExpired } from 'utils'
import {
  ActionButtons,
  BookValues,
  CustodianHistory,
  General,
  Images
} from './components'

function getStatusTag(disposalDateNumber?: number) {
  if (isExpired(disposalDateNumber)) {
    return <Tag color="red">Expired</Tag>
  } else {
    return <Tag color="green">Active</Tag>
  }
}

export const Asset = () => {
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const [loading, setLoading] = useState(true)
  const [asset, setAsset] = useState<AssetDoc | undefined>()
  const [assetRef, setAssetRef] = useState<string | undefined>()
  const companyRef = useCompanyRef()
  const user = useUser()

  useEffect(() => {
    window.scrollTo({ top: 0 })

    if (!companyRef || !id) return

    let unsubscribe: any
    ;(async () => {
      setLoading(true)

      try {
        const db = firebase.firestore()
        const assetIndexDoc = await db
          .doc(`companies/${companyRef}/assetIndex/${id}`)
          .get()
        const { assetRef } = assetIndexDoc.data() as AssetIndexDoc
        setAssetRef(assetRef)
        unsubscribe = firebase
          .firestore()
          .doc(`companies/${companyRef}/assets/${assetRef}`)
          .onSnapshot(snapshot => {
            const assetDoc = snapshot.data() as AssetDoc
            setAsset(assetDoc)
          })
      } catch (error) {
        console.error(error)
        message.error(errors.somethingWentWrong)
      }

      setLoading(false)
    })()

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [companyRef, id])

  return asset ? (
    <>
      <PageHeader
        className="site-page-header"
        style={{ marginLeft: -20 }}
        onBack={() => history.goBack()}
        title={asset?.description}
        subTitle={asset?.id}
        tags={getStatusTag(asset?.disposalDate)}
        extra={
          user?.role !== UserRole.auditor && (
            <ActionButtons
              asset={asset ? getAssetMetaFromAssetDoc(asset) : undefined}
            />
          )
        }
      />
      <General loading={loading} asset={asset} />
      <BookValues asset={asset} />
      <CustodianHistory loading={loading} asset={asset} />
      <Images
        asset={asset}
        assetRef={assetRef}
        showUploadForm={user?.role !== UserRole.auditor}
        hideable={user?.role !== UserRole.auditor}
        deleteable={[UserRole.admin, UserRole.manager].includes(
          user?.role as UserRole
        )}
      />
    </>
  ) : (
    <>
      {Array.from(Array(5).keys()).map(i => (
        <Skeleton active key={i} />
      ))}
    </>
  )
}
