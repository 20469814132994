import { deleteLocation } from 'functions'
import { deleteCategory } from 'functions/deleteCategory'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'
import { CategoryListDataItem } from 'reducers/categoryList'
import { LocationListDataItem } from 'reducers/locationList'
import { CategoryOrLocation, errors, StandardReturn } from 'utils'

export const useDeleteCateogoryLocation = (type: CategoryOrLocation) => {
  const companyRef = useSelector<AppState, string | undefined>(
    state => state.user.data?.companyRef
  )

  const deleteCategoryLocation = useCallback(
    async (
      items: (CategoryListDataItem | LocationListDataItem)[]
    ): Promise<StandardReturn> => {
      const failedRes = { success: false, error: errors.somethingWentWrong }

      if (!companyRef) return failedRes

      for (const item of items) {
        let res

        if (type === 'category') {
          res = await deleteCategory(companyRef, item.id, item.doc.name)
        } else {
          res = await deleteLocation(companyRef, item.id, item.doc.name)
        }

        if (!res.success) {
          return failedRes
        }
      }

      return { success: true }
    },
    [companyRef, type]
  )

  return deleteCategoryLocation
}
