import { UserOutlined } from '@ant-design/icons'
import {
  updateMyAssetsPageActiveAssetsTablePage,
  updateMyAssetsPageExpiredAssetsTablePage
} from 'actions'
import { Button } from 'antd'
import { ExpiredAssetsTable, StandardTable } from 'components'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useAssetMetas, useCompany } from 'hooks'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState, AssetPageState } from 'reducers'
import {
  assetActionButton,
  assetColumns,
  getAssetsDataSource,
  isExpired,
  routes
} from 'utils'

export const MyAssets = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const company = useCompany()
  const loading = useSelector<AppState, boolean>(state =>
    Boolean(state.assetList.loading)
  )
  const currentUser = firebase.auth().currentUser ?? { email: undefined }
  const assetList = useAssetMetas(
    assetMeta => assetMeta.currentCustodian === currentUser.email
  )
  const activeAssets = assetList.filter(asset => !isExpired(asset.disposalDate))
  const expiredAssets = assetList.filter(asset => isExpired(asset.disposalDate))
  const { activeAssetTablePage, expiredAssetTablePage } = useSelector<
    AppState,
    AssetPageState
  >(state => state.allAssetPage)

  const dataSource = useMemo(() => {
    if (!activeAssets) {
      return []
    }
    return getAssetsDataSource(activeAssets)
  }, [activeAssets])

  return (
    <>
      <StandardTable
        title={`Active Assets (${activeAssets.length})`}
        loading={loading}
        columns={assetColumns(company?.currency ?? '')}
        dataSource={dataSource}
        showExtras={false}
        extras={({ selectedRows }) => (
          <Button
            onClick={() =>
              history.push(routes.assignCustodian, { selectedRows })
            }
            disabled={selectedRows.length < 1}
            icon={<UserOutlined />}
          >
            Assign Custodian
          </Button>
        )}
        actionButton={assetActionButton(history)}
        pagination={{
          current: activeAssetTablePage,
          onChange: page =>
            dispatch(updateMyAssetsPageActiveAssetsTablePage(page))
        }}
      />
      <br />
      <ExpiredAssetsTable
        assets={expiredAssets}
        selectable
        pagination={{
          current: expiredAssetTablePage,
          onChange: page =>
            dispatch(updateMyAssetsPageExpiredAssetsTablePage(page))
        }}
      />
    </>
  )
}
