import { CategoryDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/firestore'
import { subscribeToListFactory } from './factories'

export const subscribeToCategoryList = subscribeToListFactory<CategoryDoc>(
  'categoryList',
  'categories',
  updateListActionType.updateCategoryList,
  updateUnsubscribeFnActionType.updateCategoryListUnsubscribeFn
)
