import { Act } from 'components'
import { updateAssets, updateCategory, updateLocation } from 'functions'
import {
  useAssetMetas,
  useAssetsMetaListDataItems,
  useCompanyRef,
  useGroups
} from 'hooks'
import { AddEditPage } from 'pages'
import React, { useCallback, useState } from 'react'
import { CategoryListDataItem, LocationListDataItem } from 'reducers'
import { CategoryOrLocation, errors, Option } from 'utils'
import {
  addCategoryLocationData,
  editCategoryLocationData
} from './add-edit-category-location-data'

interface AddCategoryLocationProps {
  dataType: CategoryOrLocation
}

export const AddCategoryLocation = ({ dataType }: AddCategoryLocationProps) => {
  const companyRef = useCompanyRef()

  return <AddEditPage {...addCategoryLocationData(dataType, companyRef)} />
}

interface EditCategoryLocationProps {
  dataType: CategoryOrLocation
  location: any
}

export const EditCategoryLocation = ({
  dataType,
  location
}: EditCategoryLocationProps) => {
  const oldDoc = location.state.selectedRows[0].value as
    | CategoryListDataItem
    | LocationListDataItem
  const companyRef = useCompanyRef()
  const groups = useGroups(dataType)
  const assetsMetaListDataItems = useAssetsMetaListDataItems()
  const affectedAssets = useAssetMetas(
    assetMeta => assetMeta[dataType] === oldDoc.doc.name
  )
  const [options, setOptions] = useState<Option[]>([])
  const [newName, setNewName] = useState(oldDoc.doc.name)

  const handleGroupNameUpdateFn: Act = async (
    { name }: { name: string },
    i
  ) => {
    const doc = { name }
    let res

    if (dataType === 'category') {
      res = await updateCategory(companyRef, oldDoc.id, oldDoc.doc.name, doc)
    } else {
      res = await updateLocation(companyRef, oldDoc.id, oldDoc.doc.name, doc)
    }

    return { res, goToStep: i + 1 }
  }
  const handleGroupNameUpdate = useCallback(handleGroupNameUpdateFn, [
    companyRef,
    dataType,
    oldDoc.doc.name,
    oldDoc.id
  ])

  const handleNewGroupNameSubmitFn: Act = async ({ name }, i) => {
    if (!affectedAssets.length) {
      const { res } = await handleGroupNameUpdate({ name }, i)

      return { res, goToStep: i + 2 }
    }

    setNewName(name)

    // remove old name, add new name and sort
    const newGroups = groups.filter(group => group !== oldDoc.doc.name)
    newGroups.push(name)
    newGroups.sort()

    const newOptions = newGroups.map(group => ({ value: group, text: group }))

    setOptions(newOptions)

    return { res: { success: true }, goToStep: i + 1 }
  }
  const handleNewGroupNameSubmit = useCallback(handleNewGroupNameSubmitFn, [
    affectedAssets.length,
    groups,
    handleGroupNameUpdate,
    oldDoc.doc.name
  ])

  const handleMoveAndUpdateFn: Act = async ({ newGroup }, i) => {
    const promises = []

    const newAssetMetas = affectedAssets.map(asset => ({
      ...asset,
      [dataType]: newGroup
    }))

    // move
    promises.push(
      (async () => {
        const res = await updateAssets(
          companyRef,
          newAssetMetas,
          assetsMetaListDataItems
        )
        return { res, goToStep: i }
      })()
    )

    // update
    promises.push(handleGroupNameUpdate({ name: newName }, i))

    const responses = await Promise.all(promises)
    const success = responses.reduce((p, c) => p && c.res.success, true)

    return success
      ? { res: { success: true }, goToStep: i + 1 }
      : {
          res: { success: false, error: errors.somethingWentWrong },
          goToStep: i
        }
  }
  const handleMoveAndUpdate = useCallback(handleMoveAndUpdateFn, [
    affectedAssets,
    assetsMetaListDataItems,
    companyRef,
    dataType,
    handleGroupNameUpdate,
    newName
  ])

  return (
    <AddEditPage
      {...editCategoryLocationData({
        dataType,
        companyRef,
        oldDoc,
        affectedAssets,
        options,
        handleGroupNameUpdate,
        handleNewGroupNameSubmit,
        handleMoveAndUpdate
      })}
    />
  )
}
