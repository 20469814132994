import { Input } from 'antd'
import { FormProps } from 'components'
import React from 'react'

const signInFormProps: FormProps = {
  layout: 'vertical',
  items: [
    {
      name: 'email',
      label: 'Email',
      rules: [
        {
          required: true
        }
      ],
      children: <Input />
    },
    {
      name: 'password',
      label: 'Password',
      rules: [{ required: true }],
      children: <Input />
    }
  ]
}

export const data = {
  title: 'Welcome to PAM - Poem Asset Manager',
  description: (
    <>
      <p>
        If you already have an account or your company admin has created an
        account for you, then you can signin to your account by clicking
        "Continue with Google".
      </p>
      <p>To register a new Company, please click "Register a new company".</p>
    </>
  ),
  signInFormProps
}
