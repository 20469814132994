import { useCompanyRef } from 'hooks'
import { AddEditPage } from 'pages'
import React from 'react'
import { addUserData, editUserData } from './add-edit-user-data'

interface AddEditUserProps {
  type: 'add' | 'edit'
  location: any
}

export const AddEditUser = ({ type, location }: AddEditUserProps) => {
  const companyRef = useCompanyRef()

  return type === 'add' ? (
    <AddEditPage {...addUserData(companyRef)} />
  ) : (
    <AddEditPage
      {...editUserData(companyRef, location.state.selectedRows[0].value.doc)}
    />
  )
}
