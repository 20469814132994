import { FileUpload, FormProps } from 'components'
import React from 'react'

const imagesFormProps: FormProps = {
  layout: 'vertical',
  items: [
    {
      name: 'images',
      label: 'Images',
      children: <FileUpload />
    }
  ]
}

export const imagesData = {
  title: 'Images',
  description: `Asset's images`,
  imagesFormProps
}
