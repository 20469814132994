import {
  DeleteOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined
} from '@ant-design/icons'
import { AssetDoc, Image } from '@poem/pam-utils'
import {
  Button,
  Col,
  Collapse,
  Form as AntForm,
  Image as AntImage,
  message,
  Row,
  Space,
  Tooltip
} from 'antd'
import { CardSection, DeleteConfirmation, Form } from 'components'
import {
  addAssetPic,
  deleteAssetPic,
  updateAssetPicHiddenStatus
} from 'functions'
import { useCompanyRef } from 'hooks'
import React, { useCallback, useState } from 'react'
import { errors } from 'utils'
import { imagesData } from './images-data'

interface ImageWithTimestamp extends Image {
  timestamp: number
}

function getImagesRows(images: ImageWithTimestamp[]) {
  const rows = []
  const IMAGES_PER_ROW = 4

  for (let i = 0; i < images.length; i += IMAGES_PER_ROW) {
    const row = []
    for (let j = 0; j < IMAGES_PER_ROW; j++) {
      const image = images[i + j]

      if (!image) {
        break
      }

      row.push(image)
    }
    rows.push(row)
  }

  return rows
}

function renderImages(
  images: ImageWithTimestamp[][],
  hideable: boolean,
  deleteable: boolean,
  companyRef?: string,
  assetRef?: string
) {
  return images.map(row => (
    <Row gutter={[16, 16]}>
      {row.map((image, i) => (
        <Col key={i} span={6}>
          <ImageComponent
            image={image}
            companyRef={companyRef}
            assetRef={assetRef}
            hideable={hideable}
            deleteable={deleteable}
          />
        </Col>
      ))}
    </Row>
  ))
}

interface ImagesProps {
  asset?: AssetDoc
  assetRef?: string
  showUploadForm?: boolean
  hideable?: boolean
  deleteable?: boolean
}

export const Images = ({
  assetRef,
  asset,
  showUploadForm = false,
  hideable = false,
  deleteable = false
}: ImagesProps) => {
  const companyRef = useCompanyRef()
  const [addImagesLoading, setAddImagesLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [form] = AntForm.useForm()

  const handleSubmit = useCallback(
    async (values: any) => {
      if (addImagesLoading || !companyRef || !assetRef || !values.images) return

      setAddImagesLoading(true)
      setFormError('')

      let success = true

      for (const item of values.images.fileList) {
        const file = item.originFileObj
        const res = await addAssetPic(companyRef, assetRef, file)
        if (!res.success) {
          success = false
        }
      }

      setAddImagesLoading(false)

      if (success) {
        message.success('Images successfully uploaded')
        form.resetFields()
      } else {
        setFormError(errors.somethingWentWrong)
      }
    },
    [addImagesLoading, assetRef, companyRef, form]
  )

  const timestamps = Object.keys(asset?.images ?? []).sort()
  const images = timestamps.map(timestamp => ({
    ...(asset as AssetDoc).images[Number(timestamp)],
    timestamp: Number(timestamp)
  }))
  const activeImages = images.filter(image => !image.hidden)
  const hiddenImages = images.filter(image => image.hidden)
  const activeImagesRows = getImagesRows(activeImages)
  const hiddenImagesRows = getImagesRows(hiddenImages)

  return (
    <>
      {showUploadForm && (
        <CardSection
          title={imagesData.title}
          description={imagesData.description}
          cardProps={{ title: imagesData.title }}
        >
          <Form
            {...imagesData.imagesFormProps}
            form={form}
            onFinish={handleSubmit}
            loading={addImagesLoading}
            formError={formError}
          />
        </CardSection>
      )}
      <br />
      {renderImages(
        activeImagesRows,
        hideable,
        deleteable,
        companyRef,
        assetRef
      )}
      {Boolean(hiddenImages.length) && (
        <Collapse>
          <Collapse.Panel header="Hidden Images" key={1}>
            {renderImages(
              hiddenImagesRows,
              hideable,
              deleteable,
              companyRef,
              assetRef
            )}
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  )
}

interface ImageComponentProps {
  image: ImageWithTimestamp
  companyRef?: string
  assetRef?: string
  hideable?: boolean
  deleteable?: boolean
}

const ImageComponent = ({
  image,
  companyRef,
  assetRef,
  hideable = false,
  deleteable = false
}: ImageComponentProps) => {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)

  const handleDelete = useCallback(async () => {
    if (deleteLoading || !companyRef || !assetRef) return

    setDeleteLoading(true)

    const res = await deleteAssetPic(companyRef, assetRef, image.timestamp)

    if (!res.success) {
      message.error(errors.somethingWentWrong)
    }

    setDeleteLoading(false)
  }, [assetRef, companyRef, image.timestamp, deleteLoading])

  const handleHiddenStatus = useCallback(
    async (newStatus: boolean) => {
      if (!companyRef || !assetRef) return

      const res = await updateAssetPicHiddenStatus(
        companyRef,
        assetRef,
        image.timestamp,
        newStatus
      )

      if (!res.success) {
        message.error(errors.somethingWentWrong)
      }
    },
    [assetRef, companyRef, image.timestamp]
  )

  const handleDownload = useCallback(
    async href => {
      if (downloadLoading) return

      setDownloadLoading(true)

      const res = await fetch(href, {
        method: 'GET',
        headers: {}
      })

      const buffer = await res.arrayBuffer()
      const url = window.URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url

      const parts = /%2F((?!%2F).)+\?/.exec(href)
      if (!parts) return
      const fileName = parts[0].replace('%2F', '').slice(0, -1)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      setDownloadLoading(false)
    },
    [downloadLoading]
  )

  return (
    <Space direction="vertical">
      <AntImage src={image.url} />
      <Space>
        <Tooltip title="Download">
          <Button
            loading={downloadLoading}
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(image.url)}
          />
        </Tooltip>

        {hideable && (
          <Tooltip title={image.hidden ? 'Show' : 'Hide'}>
            <Button
              icon={image.hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={() => handleHiddenStatus(!image.hidden)}
            />
          </Tooltip>
        )}
        {deleteable && (
          <DeleteConfirmation onConfirm={handleDelete}>
            <Button loading={deleteLoading} icon={<DeleteOutlined />} />
          </DeleteConfirmation>
        )}
      </Space>
    </Space>
  )
}
