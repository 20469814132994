import {
  updateListActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/firestore'
import { UserList } from 'reducers/userList'
import { subscribeToListFactory } from './factories'

export const subscribeToUserList = subscribeToListFactory<UserList>(
  'userList',
  'users',
  updateListActionType.updateUserList,
  updateUnsubscribeFnActionType.updateUserListUnsubscribeFn
)
