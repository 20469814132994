import { useSelector } from 'react-redux'
import { AppState, UserListDataItem } from 'reducers'

export const useUsers = () => {
  const users = useSelector<AppState, UserListDataItem[]>(
    state => state.userList.data ?? []
  )

  return users
}
