import { AssetMeta } from '@poem/pam-utils'
import { message } from 'antd'
import { deleteAsset } from 'functions'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { errors, routes } from 'utils'
import { useAssetsMetaListDataItems } from './useAssetsMetaListDataItems'
import { useCompanyRef } from './useCompanyRef'

export const useAssetActions = (asset?: AssetMeta) => {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const history = useHistory()
  const companyRef = useCompanyRef()
  const assetsMetaListDataItems = useAssetsMetaListDataItems()

  const handleDelete = useCallback(async () => {
    if (deleteLoading || !companyRef || !asset) return

    setDeleteLoading(true)

    const res = await deleteAsset(companyRef, asset, assetsMetaListDataItems)

    setDeleteLoading(false)

    if (res.success) {
      message.success('Asset successfully deleted')
      history.goBack()
      return
    } else {
      message.error(errors.somethingWentWrong)
    }
  }, [asset, assetsMetaListDataItems, companyRef, deleteLoading, history])

  const handleEdit = useCallback(() => {
    if (!asset) {
      message.error(errors.somethingWentWrong)
      return
    }

    history.push(routes.editAsset, { selectedRows: [{ value: asset }] })
  }, [history, asset])

  const handleAdd = useCallback(() => {
    history.push(routes.addAsset)
  }, [history])

  const handleAssignCustodian = useCallback(() => {
    history.push(routes.assignCustodian, { selectedRows: [{ value: asset }] })
  }, [asset, history])

  return {
    handleAdd,
    handleEdit,
    handleDelete,
    handleAssignCustodian,
    deleteLoading
  }
}
