import { Collapse, Typography } from 'antd'
import { StandardTable } from 'components'
import React from 'react'
import { AssetChanges } from '../bulk-add-update-assets-interfaces'

const columns = [
  {
    title: 'Property',
    dataIndex: 'property'
  },
  {
    title: 'Changes',
    dataIndex: 'value'
  }
]

export interface UpdateAssetsSummaryProps {
  assetChangesList: AssetChanges[]
}

export const UpdateAssetsSummary = ({
  assetChangesList
}: UpdateAssetsSummaryProps) => (
  <>
    <Typography.Title level={5}>
      Assets Updates ({assetChangesList.length})
    </Typography.Title>
    {!assetChangesList.length && <p>No asset updates</p>}
    <Collapse>
      {assetChangesList.map((assetChanges, i) => {
        const dataSource = assetChanges.changes.map(change => ({
          property: change.propertyName,
          value: (
            <span>
              <del>{change.oldValue}</del> {change.newValue}
            </span>
          )
        }))

        return (
          <Collapse.Panel
            header={`${assetChanges.newAsset.description} (${assetChanges.newAsset.id})`}
            key={i}
          >
            <StandardTable
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 20, hideOnSinglePage: true }}
              showExtras={false}
              selectable={false}
            />
          </Collapse.Panel>
        )
      })}
    </Collapse>
  </>
)
