import { CompanyDoc } from '@poem/pam-utils'
import {
  updateDocActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface Company {
  data?: CompanyDoc
  loading?: boolean
  unsubscribe?: () => void
}

export const company = docAndCollectionReducerFactory<CompanyDoc, CompanyDoc>(
  updateDocActionType.updateCompany,
  updateUnsubscribeFnActionType.updateCompanyUnsubscribeFn,
  updateLoadingStatusActionType.updateCompanyLoadingStatus
)
