import { commonCurrencies } from '@poem/pam-utils'
import { getDateFromDateNumber } from '@poem/utils'
import { Button, Result, Steps } from 'antd'
import { Card, Center, Form, Section } from 'components'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'reducers/appState'
import { CategoryList } from 'reducers/categoryList'
import { LocationList } from 'reducers/locationList'
import { AddEditType, routes } from 'utils'
import { data } from './add-edit-asset-data'
import { DepreciationForm } from './components'

const getMomentTimeFromDateNumber = (date?: number) => {
  if (!date) return undefined

  const { day, month, year } = getDateFromDateNumber(date)

  return moment({ day, month, year })
}

interface AddAssetProps {
  type: AddEditType
  selectedRows?: any[]
}

export const AddEditAsset = ({ type, selectedRows = [] }: AddAssetProps) => {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState(0)
  const [generalValues, setGeneralValues] = useState<any>({})

  const categories = useSelector<AppState, CategoryList>(
    state => state.categoryList
  )
  const locations = useSelector<AppState, LocationList>(
    state => state.locationList
  )
  const currencyCode = useSelector<AppState, string | undefined>(
    state => state.company.data?.currency
  )

  const formProps = data(type).generalFormProps(
    categories,
    locations,
    currencyCode ? commonCurrencies[currencyCode].symbol : ''
  )

  const initialValues = selectedRows[0]?.value

  return (
    <Section
      title={data(type).title}
      description={data(type).description}
      extras={
        <Steps direction="vertical" current={currentStep}>
          {data(type).steps.map(step => (
            <Steps.Step title={step.title} description={step.description} />
          ))}
        </Steps>
      }
    >
      {currentStep === 0 && (
        <Center>
          <Card title={data(type).steps[currentStep].title}>
            <Form
              {...(type === 'add'
                ? formProps
                : {
                    ...formProps,
                    initialValues: {
                      ...initialValues,
                      purchaseDate: getMomentTimeFromDateNumber(
                        initialValues.purchaseDate
                      ),
                      disposalDate: getMomentTimeFromDateNumber(
                        initialValues.disposalDate
                      )
                    }
                  })}
              onFinish={values => {
                setGeneralValues(values)
                setCurrentStep(currentStep + 1)
              }}
            />
          </Card>
        </Center>
      )}
      {currentStep === 1 && (
        <DepreciationForm
          generalValues={generalValues}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          initialValues={initialValues}
          type={type}
        />
      )}
      {currentStep === 2 && (
        <Result
          status="success"
          title={`Asset ${type === 'add' ? 'Created' : 'Updated'} Successfully`}
          subTitle="Click continue to go back to Asset List"
          extra={
            <Button
              type="primary"
              onClick={() => history.push(routes.allAssets)}
            >
              Continue
            </Button>
          }
        />
      )}
    </Section>
  )
}
