import { Layout } from 'antd'
import { Base, Footer } from 'components'
import React from 'react'
import { LayoutComponentProps } from 'utils'

export const AuthLayout = ({ children }: LayoutComponentProps) => (
  <Layout>
    <Layout.Content>
      <Base>{children}</Base>
    </Layout.Content>
    <Footer />
  </Layout>
)
