import capitalize from 'capitalize'
import { TableSection } from 'components'
import 'firebase/auth'
import { deleteUser } from 'functions'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'
import { UserListDataItem } from 'reducers/userList'
import { errors, routes, StandardReturn } from 'utils'
import { data } from './user-list-data'

// const includesYou = (selected: any) => {
//   for (const id of Object.keys(selected)) {
//     const user = selected[id]
//     if (user.email === firebase.auth().currentUser?.email) return true
//   }

//   return false
// }

export interface UserItem {
  name: string
  email: string
  role: string
  value: UserListDataItem
}

export const UserList = () => {
  const users = useSelector<AppState, UserListDataItem[]>(
    state => state.userList.data ?? []
  )

  const companyRef = useSelector<AppState, string | undefined>(
    state => state.user.data?.companyRef
  )

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Role',
      dataIndex: 'role'
    }
  ]

  const dataSource = useMemo(
    () =>
      users.map(value => ({
        key: value.doc.email,
        email: value.doc.email,
        name: value.doc.name,
        role: capitalize(value.doc.role),
        value
      })),
    [users]
  )

  const handleDelete = useCallback(
    async ({
      selectedRows
    }: {
      selectedRows: any[]
    }): Promise<StandardReturn> => {
      const failedRes = { success: false, error: errors.somethingWentWrong }

      if (!companyRef) return failedRes

      const items = selectedRows.map(row => row.value as UserListDataItem)

      for (const item of items) {
        const res = await deleteUser(companyRef, item.doc.email)

        if (!res.success) {
          return failedRes
        }
      }

      return { success: true }
    },
    [companyRef]
  )

  return (
    <TableSection
      title={data.title}
      description={data.description}
      tableProps={{
        title: data.title,
        addRoute: routes.addUser,
        editRoute: routes.editUser,
        columns,
        dataSource,
        loading: !Boolean(users),
        handleDelete
      }}
    />
  )
}
