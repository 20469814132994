import { Image } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { errors, StandardReturn } from 'utils'

async function uploadAssetPic(
  companyRef: string,
  assetRef: string,
  file: File
) {
  const timestamp = Date.now()
  const ref = firebase
    .storage()
    .ref(`companies/${companyRef}/assets/${assetRef}/${timestamp}.png`)
  await ref.put(file)
  const url = await ref.getDownloadURL()

  // add url to images in AssetDoc
  const db = firebase.firestore()
  const batch = db.batch()
  const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
  const assetDocUpdatePath = `images.${timestamp}`

  const image: Image = {
    hidden: false,
    url
  }

  const assetDocUpdates = {
    [assetDocUpdatePath]: image
  }

  batch.update(assetDocRef, assetDocUpdates)

  await batch.commit()
}

export async function addAssetPic(
  companyRef: string,
  assetRef: string,
  file: File
): Promise<StandardReturn> {
  try {
    await uploadAssetPic(companyRef, assetRef, file)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
