import {
  AssetCustodianChangeRequest,
  AssetDoc,
  AssetsMetaDoc
} from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { batchUpdateAssetsMetaDoc } from 'functions'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import { errors, StandardReturn } from 'utils'

async function custodianChangeRequestAcceptUpdateDocs(
  companyRef: string,
  assetCustodianChangeRequest: AssetCustodianChangeRequest
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update pendingCustodian and assignee in AssetDoc
  const assetDocRef = db.doc(
    `companies/${companyRef}/assets/${assetCustodianChangeRequest.assetRef}`
  )
  const assetDocUpdates: Partial<AssetDoc> = {
    pendingCustodian: null,
    assignee: null,
    currentCustodian: assetCustodianChangeRequest.pendingCustodian
  }

  // remove request for new custodian in AssetDoc
  const userDocRef = db.doc(
    `companies/${companyRef}/users/${assetCustodianChangeRequest.pendingCustodian}`
  )
  const userDocUpdates = {
    requests: firebase.firestore.FieldValue.arrayRemove(
      assetCustodianChangeRequest
    )
  }

  batch.update(assetDocRef, assetDocUpdates)
  batch.update(userDocRef, userDocUpdates)

  await batch.commit()
}

// export async function custodianChangeRequestAccept(
//   companyRef: string,
//   assetCustodianChangeRequest: AssetCustodianChangeRequest
// ): Promise<StandardReturn> {
//   try {
//     await custodianChangeRequestAcceptUpdateDocs(
//       companyRef,
//       assetCustodianChangeRequest
//     )
//   } catch (error) {
//     console.error(error)
//     return { success: false, error: errors.somethingWentWrong }
//   }

//   return { success: true }
// }

function getNewAssetsMetaDocs(
  assetsMetaItems: AssetsMetaListDataItem[],
  assetCustodianChangeRequests: AssetCustodianChangeRequest[]
): { doc: AssetsMetaDoc; id: string }[] {
  const requestsMap: { [id: string]: AssetCustodianChangeRequest } = {}

  for (const assetMeta of assetCustodianChangeRequests) {
    requestsMap[assetMeta.id] = assetMeta
  }

  const newAssetsMetaDocs: { doc: AssetsMetaDoc; id: string }[] = []

  for (const item of assetsMetaItems) {
    // find all assets in this item and remove them from doc
    let containAsset = false
    let newAssets = [...item.doc.assets]

    for (let i = 0; i < newAssets.length; i++) {
      const am = newAssets[i]

      if (requestsMap[am.id]) {
        containAsset = true
        newAssets[i] = {
          ...newAssets[i],
          pendingCustodian: null,
          currentCustodian: requestsMap[am.id].pendingCustodian
        }
      }
    }

    if (containAsset) {
      let newAssetsMetaDoc = { assets: newAssets }
      newAssetsMetaDocs.push({ doc: newAssetsMetaDoc, id: item.id })
    }
  }

  return newAssetsMetaDocs
}

export async function custodianChangeRequestsAccept(
  companyRef: string,
  assetsMetaListDataItems: AssetsMetaListDataItem[],
  assetCustodianChangeRequests: AssetCustodianChangeRequest[]
): Promise<StandardReturn> {
  const promises = []

  for (const request of assetCustodianChangeRequests) {
    promises.push(custodianChangeRequestAcceptUpdateDocs(companyRef, request))
  }

  const newAssetsMetaDocs = getNewAssetsMetaDocs(
    assetsMetaListDataItems,
    assetCustodianChangeRequests
  )

  for (const newAssetsMetaDoc of newAssetsMetaDocs) {
    const db = firebase.firestore()
    const batch = db.batch()

    batchUpdateAssetsMetaDoc(
      batch,
      companyRef,
      newAssetsMetaDoc.id,
      newAssetsMetaDoc.doc
    )

    promises.push(batch.commit())
  }

  try {
    await Promise.all(promises)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
