import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons'
import { AssetMeta } from '@poem/pam-utils'
import { Button, Space } from 'antd'
import { DeleteConfirmation } from 'components'
import { useAssetActions } from 'hooks'
import React from 'react'
import { isExpired } from 'utils'

interface ActionButtonsProps {
  asset?: AssetMeta
}

export const ActionButtons = ({ asset }: ActionButtonsProps) => {
  const {
    deleteLoading,
    handleAssignCustodian,
    handleEdit,
    handleDelete
  } = useAssetActions(asset)

  const expired = isExpired(asset?.disposalDate)

  return (
    <Space>
      {!expired && (
        <>
          <Button
            data-test="asset-edit-btn"
            onClick={handleEdit}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
        </>
      )}
      <DeleteConfirmation onConfirm={handleDelete}>
        <Button
          data-test="asset-delete-btn"
          loading={deleteLoading}
          icon={<DeleteOutlined />}
        >
          Delete
        </Button>
      </DeleteConfirmation>
      <Button
        data-test="asset-assign-custodian-btn"
        onClick={handleAssignCustodian}
        icon={<UserOutlined />}
      >
        Assign Custodian
      </Button>
    </Space>
  )
}
