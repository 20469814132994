import { AssetMeta, CompanyDoc, getAssetBookValues } from '@poem/pam-utils'
import { getDateFromDateNumber, printDateNumber, roundTo2dp } from '@poem/utils'
import currencyFormatter from 'currency-formatter'
import { getJsDateFromExcel } from 'excel-date-to-js'
import {
  errors as errorMessages,
  ExcelSchema,
  MAX_DEPRECIATION_PERCENTAGES_LENGTH,
  MAX_TEXT_FIELD_LENGTH
} from 'utils'
import validator from 'validator'
import { Asset, AssetChanges } from './bulk-add-update-assets-interfaces'

const parseDate = (name: string) => (value: string | Date) => {
  if (value instanceof Date) {
    const [day, month, year] = [
      value.getDate(),
      value.getMonth(),
      value.getFullYear()
    ]
    return year * 10000 + month * 100 + day
  }

  // check if the value given is either a number >= 0 or given in date format
  const isDateFormat = validator.isDate(value, {
    format: 'DD/MM/YYYY',
    strictMode: true
  })
  const isExcelDate = !Number.isNaN(Number(value)) && Number(value) >= 0

  if (!(isDateFormat || isExcelDate)) {
    throw new Error(errorMessages.invalid(name))
  }

  let day, month, year

  if (isDateFormat) {
    const [d, m, y] = value.split('/').map(v => Number(v))
    day = d
    month = m - 1
    year = y
  } else {
    // handle excel date
    const date = getJsDateFromExcel(Number(value))
    day = date.getDate()
    month = date.getMonth()
    year = date.getFullYear()
  }

  return year * 10000 + month * 100 + day
}

export const assetFileSchema: ExcelSchema = {
  ID: {
    prop: 'id',
    type: String,
    required: true
  },
  Name: {
    prop: 'description',
    type: String,
    required: true
  },
  Category: {
    prop: 'category',
    type: String,
    required: true
  },
  Location: {
    prop: 'location',
    type: String,
    required: true
  },
  'Purchase Date': {
    prop: 'purchaseDate',
    type: parseDate('purchase date'),
    required: true
  },
  'Disposal Date': {
    prop: 'disposalDate',
    type: parseDate('disposal date')
  },
  'Purchase Price': {
    prop: 'purchasePrice',
    type: value => roundTo2dp(Number(value)),
    required: true
  },
  'Scrap Value': {
    prop: 'scrapValue',
    type: value => roundTo2dp(Number(value)),
    required: true
  },
  'Yearly Depreciation Percentage': {
    prop: 'yearlyDepreciationPercentages',
    type: value => {
      const percentages = String(value)
        .split(',')
        .map(v => roundTo2dp(Number(v)))

      for (const percentage of percentages) {
        if (isNaN(percentage)) {
          throw new Error('Invalid yearly depreciation percentages')
        }
      }

      return percentages
    },
    required: true
  }
}

export const validateAsset = (
  asset: Asset,
  expiredAssetsMap: { [id: string]: AssetMeta },
  categoriesMap: { [category: string]: true },
  locationsMap: { [location: string]: true }
) => {
  let valid = true
  let errors = []

  // check that the asset is not updating an expired asset
  if (expiredAssetsMap[asset.id]) {
    valid = false
    errors.push(errorMessages.updatingExpiredAsset)
  }

  // check if category exists
  if (!categoriesMap[asset.category]) {
    valid = false
    errors.push(errorMessages.doesNotExists('category'))
  }

  // check if location exists
  if (!locationsMap[asset.location]) {
    valid = false
    errors.push(errorMessages.doesNotExists('location'))
  }

  // check if id has a length of more than max length
  if (asset.id.length > MAX_TEXT_FIELD_LENGTH) {
    valid = false
    errors.push(errorMessages.maxLengthExceeded('id', MAX_TEXT_FIELD_LENGTH))
  }

  // check if description has a length of more than max length
  if (asset.description.length > MAX_TEXT_FIELD_LENGTH) {
    valid = false
    errors.push(errorMessages.maxLengthExceeded('name', MAX_TEXT_FIELD_LENGTH))
  }

  // check that disposal date is after purchase date
  if (asset.disposalDate < asset.purchaseDate) {
    valid = false
    errors.push(errorMessages.assetDisposalDateBeforePurchaseDate)
  }

  // check that scrap value is less than purchase price
  if (asset.scrapValue > asset.purchasePrice) {
    valid = false
    errors.push(errorMessages.assetScrapValueMoreThanPurchasePrice)
  }

  // check that all depreciation percentage has a value of >= 0 and <= 100
  if (
    !asset.yearlyDepreciationPercentages.every(
      percentage => percentage >= 0 && percentage <= 100
    )
  ) {
    valid = false
    errors.push(errorMessages.assetYearlyDepreciationPercentageOutOfRange)
  }

  // check that depreciation percentage has length of less than 100
  if (
    asset.yearlyDepreciationPercentages.length >
    MAX_DEPRECIATION_PERCENTAGES_LENGTH
  ) {
    valid = false
    errors.push(errorMessages.assetYearlyDepreciationPercentagesTooLong)
  }

  // check that the asset will take less than 100 years to fully depreciate
  const { day, month, year } = getDateFromDateNumber(asset.purchaseDate)
  const { tooLong } = getAssetBookValues(
    day,
    month,
    year,
    asset.purchasePrice,
    asset.scrapValue,
    asset.yearlyDepreciationPercentages
  )

  if (tooLong) {
    valid = false
    errors.push(errorMessages.assetDepreciateTooLong)
  }

  return { valid, errors }
}

export const isYearlyDepreciationPercentagesEqual = (
  oldValue: number[],
  newValue: number[]
): boolean => {
  if (oldValue.length !== newValue.length) {
    return false
  }

  for (let i = 0; i < oldValue.length; i++) {
    if (oldValue[i] !== newValue[i]) {
      return false
    }
  }

  return true
}

export const getAssetUpdates = (
  oldAsset: AssetMeta,
  newAsset: Asset,
  company?: CompanyDoc
): AssetChanges => {
  const changes: AssetChanges['changes'] = []

  for (const attr of Object.keys(newAsset) as (keyof Asset)[]) {
    if (
      (typeof oldAsset[attr] === 'string' ||
        typeof oldAsset[attr] === 'number') &&
      oldAsset[attr] !== newAsset[attr]
    ) {
      let oldValue = oldAsset[attr]
      let newValue = newAsset[attr]

      if (attr === 'disposalDate' || attr === 'purchaseDate') {
        oldValue = printDateNumber(oldAsset[attr])
        newValue = printDateNumber(newAsset[attr])
      }

      if ((attr === 'purchasePrice' || attr === 'scrapValue') && company) {
        oldValue = currencyFormatter.format(oldAsset[attr], {
          code: company.currency
        })
        newValue = currencyFormatter.format(newAsset[attr], {
          code: company.currency
        })
      }

      changes.push({
        propertyName: attr,
        oldValue: String(oldValue),
        newValue: String(newValue)
      })
    }
  }

  if (
    !isYearlyDepreciationPercentagesEqual(
      oldAsset.yearlyDepreciationPercentages,
      newAsset.yearlyDepreciationPercentages
    )
  ) {
    changes.push({
      propertyName: 'yearlyDepreciationPercentages',
      oldValue: oldAsset.yearlyDepreciationPercentages.join(', '),
      newValue: newAsset.yearlyDepreciationPercentages.join(', ')
    })
  }

  return {
    newAsset: {
      ...newAsset,
      assignee: oldAsset.assignee,
      pendingCustodian: oldAsset.pendingCustodian,
      currentCustodian: oldAsset.currentCustodian
    },
    changes
  }
}
