import { UserDoc } from '@poem/pam-utils'
import {
  updateDocActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface User {
  data?: UserDoc
  loading?: boolean
  unsubscribe?: () => void
}

export const user = docAndCollectionReducerFactory<UserDoc, UserDoc>(
  updateDocActionType.updateUser,
  updateUnsubscribeFnActionType.updateUserUnsubscribeFn,
  updateLoadingStatusActionType.updateUserLoadingStatus
)
