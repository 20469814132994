import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, InputNumber, Select } from 'antd'
import { FormProps } from 'components'
import { Moment } from 'moment-timezone'
import React from 'react'
import { CategoryList } from 'reducers/categoryList'
import { LocationList } from 'reducers/locationList'
import { AddEditType } from 'utils'

const generalFormProps = (
  categories: CategoryList,
  locations: LocationList,
  currency: string
): FormProps => ({
  layout: 'vertical',
  submitButton: buttonProps => (
    <Button {...buttonProps}>
      Next <ArrowRightOutlined />
    </Button>
  ),
  items: [
    {
      name: 'id',
      label: 'ID',
      rules: [
        {
          required: true
        }
      ],
      children: <Input data-test="id-input" />
    },
    {
      name: 'description',
      label: 'Name',
      rules: [{ required: true }],
      children: <Input data-test="name-input" />
    },
    {
      name: 'category',
      label: 'Category',
      rules: [{ required: true }],
      children: (
        <Select data-test="category-select">
          {categories.data
            ? categories.data.map((category, i) => (
                <Select.Option key={i} value={category.doc.name}>
                  {category.doc.name}
                </Select.Option>
              ))
            : []}
        </Select>
      )
    },
    {
      name: 'location',
      label: 'Location',
      rules: [{ required: true }],
      children: (
        <Select data-test="location-select">
          {locations.data
            ? locations.data.map((location, i) => (
                <Select.Option key={i} value={location.doc.name}>
                  {location.doc.name}
                </Select.Option>
              ))
            : []}
        </Select>
      )
    },
    {
      name: 'purchaseDate',
      label: 'Purchase Date',
      rules: [{ required: true }],
      children: <DatePicker data-test="purchase-date-picker" />
    },
    {
      name: 'disposalDate',
      label: 'Disposal Date',
      rules: [
        ({ getFieldValue }) => ({
          validator: (rule, value: Moment) => {
            if (
              !value ||
              !getFieldValue('purchaseDate') ||
              (getFieldValue('purchaseDate') as Moment).diff(value) < 0
            ) {
              return Promise.resolve()
            }

            return Promise.reject('Disposal Date must be after Purchase Date.')
          }
        })
      ],
      children: <DatePicker data-test="disposal-date-picker" />
    },
    {
      name: 'purchasePrice',
      label: 'Purchase Price',
      rules: [{ required: true }],
      children: <InputNumber data-test="purchase-price-input" min={0} />
    },
    {
      name: 'scrapValue',
      label: 'Scrap Value',
      rules: [
        { required: true },
        ({ getFieldValue }) => ({
          validator: (rule, value) => {
            if (
              !value ||
              !getFieldValue('purchasePrice') ||
              value < getFieldValue('purchasePrice')
            ) {
              return Promise.resolve()
            }

            return Promise.reject(
              'Scrap Value must be less than Purchase Price'
            )
          }
        })
      ],
      children: <InputNumber data-test="scrap-value-input" min={0} />
    }
  ]
})

export const data = (type: AddEditType) => ({
  title: type === 'add' ? 'Create a new Asset' : 'Edit Asset',
  description:
    type === 'add' ? (
      <>
        <p>Please fill in the required information to create an asset.</p>
        <p>
          If you've not created a location and a category yet, you can create
          them in the "Settings" page.
        </p>
      </>
    ) : (
      <p>Please fill in the required information to update the asset.</p>
    ),
  steps: [
    {
      title: 'General',
      description: 'General asset data'
    },
    {
      title: 'Depreciation',
      description: `Define asset's yearly depreciation percentages`
    },
    {
      title: 'Done',
      description: 'Your new asset has been created!'
    }
  ],
  generalFormProps
})
