import { UserDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface UserListDataItem {
  id: string
  doc: UserDoc
}

export interface UserList {
  data?: UserListDataItem[]
  loading?: boolean
  unsubscribe?: () => void
}

export const userList = docAndCollectionReducerFactory<
  UserDoc,
  UserListDataItem
>(
  updateListActionType.updateUserList,
  updateUnsubscribeFnActionType.updateUserListUnsubscribeFn,
  updateLoadingStatusActionType.updateUserListLoadingStatus
)
