import { CompanyDoc } from '@poem/pam-utils'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'

export const useCompany = () => {
  const company = useSelector<AppState, CompanyDoc | undefined>(
    state => state.company.data
  )

  return company
}
