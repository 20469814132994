import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'

async function deleteLocationDocs(
  companyRef: string,
  locationRef: string,
  locationName: string
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create LocationDoc
  const locationDocRef = db.doc(
    `companies/${companyRef}/locations/${locationRef}`
  )
  batch.delete(locationDocRef)

  const locationIndexDocRef = db.doc(
    `companies/${companyRef}/locationIndex/${locationName}`
  )
  batch.delete(locationIndexDocRef)

  await batch.commit()
}

export async function deleteLocation(
  companyRef: string,
  locationRef: string,
  locationName: string
): Promise<StandardReturn> {
  try {
    await deleteLocationDocs(companyRef, locationRef, locationName)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
