import { Collapse, Typography } from 'antd'
import { StandardTable } from 'components'
import React from 'react'
import { AssetErrors } from '../bulk-add-update-assets-interfaces'

const columns = [
  {
    title: 'Errors',
    dataIndex: 'value'
  }
]

export interface ErrorAssetsSummaryProps {
  assetErrors: AssetErrors[]
}

export const ErrorAssetsSummary = ({
  assetErrors
}: ErrorAssetsSummaryProps) => (
  <>
    <Typography.Title level={5}>
      Asset Errors ({assetErrors.length})
    </Typography.Title>
    <p>These assets will be ignored</p>
    <Collapse>
      {assetErrors.map((assetError, i) => {
        const dataSource = assetError.errors.map(error => ({
          value: error
        }))

        return (
          <Collapse.Panel
            header={`${assetError.asset.description} (${assetError.asset.id})`}
            key={i}
          >
            <StandardTable
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 20, hideOnSinglePage: true }}
              showExtras={false}
              selectable={false}
            />
          </Collapse.Panel>
        )
      })}
    </Collapse>
  </>
)
