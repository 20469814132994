import { GoogleOutlined } from '@ant-design/icons'
import { commonCurrencies } from '@poem/pam-utils'
import { Button, Input, Select } from 'antd'
import { FormProps } from 'components'
import { isCompanyNameAvailable } from 'functions'
import React from 'react'

export const registerFormProps: FormProps = {
  layout: 'vertical',
  submitButton: buttonProps => (
    <Button data-test="continue-with-google-btn" {...buttonProps}>
      <GoogleOutlined /> Continue with Google
    </Button>
  ),
  items: [
    {
      name: 'name',
      label: 'Company Name',
      hasFeedback: true,
      rules: [
        {
          required: true
        },
        {
          validator: async (rule, value) => {
            if (!value) return

            const available = await isCompanyNameAvailable(value)
            if (available) {
              return
            }

            throw Error('This company name is already taken.')
          }
        }
      ],
      children: <Input data-test="company-name-input" />
    },
    {
      name: 'currency',
      label: 'Currency',
      initialValue: 'THB',
      rules: [
        {
          required: true
        }
      ],
      children: (
        <Select data-test="currency-select">
          {Object.keys(commonCurrencies).map((code, i) => (
            <Select.Option
              key={i}
              value={code}
            >{`${commonCurrencies[code].name} (${code})`}</Select.Option>
          ))}
        </Select>
      )
    }
  ]
}

export const data = {
  title: 'Register a New Company',
  description: (
    <>
      <p>
        To register a new company with PAM, please fill in the required
        information and click "Continue with Google".
      </p>
      <p>
        Your company and your admin account will be create once you've choosen
        on a Google account to be linked with PAM. At this point you'll be taken
        to your dashboard where you can start adding locations, categories and
        assets.
      </p>
      <p>
        If you already have an account and would like to sign in, please click
        "Signin".
      </p>
    </>
  ),
  registerFormProps
}
