import { AssetDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface AssetListDataItem {
  id: string
  doc: AssetDoc
}

export interface AssetList {
  data?: AssetListDataItem[]
  loading?: boolean
  unsubscribe?: () => void
}

export const assetList = docAndCollectionReducerFactory<
  AssetDoc,
  AssetListDataItem[]
>(
  updateListActionType.updateAssetList,
  updateUnsubscribeFnActionType.updateAssetListUnsubscribeFn,
  updateLoadingStatusActionType.updateAssetListLoadingStatus
)
