import { useSelector } from 'react-redux'
import { AppState, CategoryListDataItem, LocationListDataItem } from 'reducers'
import { CategoryOrLocation } from 'utils'

export const useGroupListDataItems = (type: CategoryOrLocation) => {
  const groupListDataItems = useSelector<
    AppState,
    CategoryListDataItem[] | LocationListDataItem[]
  >(
    state =>
      state[type === 'category' ? 'categoryList' : 'locationList'].data || []
  )

  return groupListDataItems
}
