import { UserDoc } from '@poem/pam-utils'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers'

export const useUser = () => {
  const user = useSelector<AppState, UserDoc | undefined>(
    state => state.user.data
  )

  return user
}
