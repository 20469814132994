import { LocationDoc, LocationIndexDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'
import { v4 as uuid } from 'uuid'

export async function isLocationNameAvailable(
  companyRef: string,
  locationName: string
) {
  const doc = await firebase
    .firestore()
    .doc(`companies/${companyRef}/locationIndex/${locationName}`)
    .get()

  return !doc.exists
}

async function createLocationDocs(
  companyRef: string,
  locationDoc: LocationDoc
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create LocationDoc
  const locationRef = uuid()
  const categoryDocRef = db.doc(
    `companies/${companyRef}/locations/${locationRef}`
  )
  batch.set(categoryDocRef, locationDoc)

  const locationIndexDocRef = db.doc(
    `companies/${companyRef}/locationIndex/${locationDoc.name}`
  )
  const locationIndexDoc: LocationIndexDoc = {
    locationRef
  }
  batch.set(locationIndexDocRef, locationIndexDoc)

  await batch.commit()
}

export async function createLocation(
  companyRef: string,
  locationDoc: LocationDoc
): Promise<StandardReturn> {
  const locationNameAvailable = await isLocationNameAvailable(
    companyRef,
    locationDoc.name
  )

  if (!locationNameAvailable) {
    return { success: false, error: errors.notAvailable('location', 'name') }
  }

  try {
    await createLocationDocs(companyRef, locationDoc)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
