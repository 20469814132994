import { AssetMeta } from '@poem/pam-utils'
import { Act } from 'components'
import { updateAssets } from 'functions'
import { Location } from 'history'
import {
  useAssetsMetaListDataItems,
  useCompanyRef,
  useDeleteCateogoryLocation,
  useGroupOptions
} from 'hooks'
import { AddEditPage } from 'pages'
import React, { useCallback } from 'react'
import { CategoryListDataItem, LocationListDataItem } from 'reducers'
import { CategoryOrLocation, errors } from 'utils'
import { data } from './move-asset-and-delete-category-location-data'

interface MoveAssetAndDeleteCategoryLocationProps {
  type: CategoryOrLocation
  location: Location
}

export const MoveAssetAndDeleteCategoryLocation = ({
  type,
  location
}: MoveAssetAndDeleteCategoryLocationProps) => {
  const { affectedAssets, items } = location.state as {
    affectedAssets: AssetMeta[]
    items: (CategoryListDataItem | LocationListDataItem)[]
  }
  const options = useGroupOptions(
    type,
    items.map(item => item.doc.name)
  )
  const companyRef = useCompanyRef()
  const assetsMetaListDataItems = useAssetsMetaListDataItems()
  const deleteCategoryLocation = useDeleteCateogoryLocation(type)

  const handleMoveAndDeleteFn: Act = async ({ newGroup }, i) => {
    const promises = []

    const newAssetMetas = affectedAssets.map(asset => ({
      ...asset,
      [type]: newGroup
    }))

    // move
    promises.push(
      updateAssets(companyRef, newAssetMetas, assetsMetaListDataItems)
    )

    // delete
    promises.push(deleteCategoryLocation(items))

    const responses = await Promise.all(promises)
    const success = responses.reduce((p, c) => p && c.success, true)

    return success
      ? { res: { success: true }, goToStep: i + 1 }
      : {
          res: { success: false, error: errors.somethingWentWrong },
          goToStep: i
        }
  }
  const handleMoveAndDelete = useCallback(handleMoveAndDeleteFn, [])

  const props = data(type, handleMoveAndDelete, affectedAssets, options)

  return <AddEditPage {...props} />
}
