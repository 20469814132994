import { AssetMeta } from '@poem/pam-utils'
import { Select } from 'antd'
import { FormProps, MultiStepFormProps, Table } from 'components'
import firebase from 'firebase/app'
import 'firebase/auth'
import { assignCustodian } from 'functions'
import React from 'react'
import { AssetsMetaListDataItem, UserListDataItem } from 'reducers'
import { errors, getAssetsDataSource, getSuccessStep, routes } from 'utils'

const formProps = (
  assetMetas: AssetMeta[],
  users: UserListDataItem[]
): FormProps => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Category',
      dataIndex: 'category'
    },
    {
      title: 'Location',
      dataIndex: 'location'
    }
  ]
  const dataSource = getAssetsDataSource(assetMetas)

  return {
    layout: 'vertical',
    items: [
      {
        children: (
          <>
            <div>New custodian will be assigned to assets below</div>
            <br />
            <Table
              columns={columns}
              dataSource={dataSource}
              selectable={false}
            />
          </>
        )
      },
      {
        name: 'pendingCustodian',
        label: 'New Custodian',
        rules: [{ required: true }],
        children: (
          <Select>
            {users.map((user, i) => (
              <Select.Option key={i} value={user.doc.email}>
                {user.doc.email}
              </Select.Option>
            ))}
          </Select>
        )
      }
    ]
  }
}

export const assignCustodianData = (
  users: UserListDataItem[],
  companyRef: string,
  assetMetas: AssetMeta[],
  assetsMetaListDataItems: AssetsMetaListDataItem[]
): MultiStepFormProps => ({
  title: 'Assign a custodian',
  description: 'Please select a custodian and click submit to assign',
  steps: [
    {
      type: 'form',
      title: 'Custodian',
      description: 'Assign a custodian',
      formProps: formProps(assetMetas, users),
      act: async (values, i) => {
        const pendingCustodian = values.pendingCustodian
        const assignee = firebase.auth().currentUser?.email

        if (!assignee)
          return {
            res: { success: false, error: errors.somethingWentWrong },
            goToStep: i
          }

        const res = await assignCustodian(
          companyRef,
          assetMetas,
          assetsMetaListDataItems,
          pendingCustodian,
          assignee
        )

        return { res, goToStep: i + 1 }
      }
    },
    getSuccessStep('custodian', 'assigned', routes.requests, 'Requests')
  ]
})
