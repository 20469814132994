import { getDateFromDateNumber, Month } from '@poem/utils'

export interface DateObj {
  day: number
  month: Month
  year: number
}

export function isDateObj(obj: any): obj is DateObj {
  return (
    typeof obj === 'object' &&
    typeof obj.day === 'number' &&
    typeof obj.year === 'number' &&
    typeof obj.month === 'number' &&
    obj.month >= 0 &&
    obj.month <= 11
  )
}

export function isExpired(dateNumber?: number) {
  if (!dateNumber) {
    return false
  }

  const { day, month, year } = getDateFromDateNumber(dateNumber)
  const date = new Date(year, month, day)

  return date < new Date()
}
