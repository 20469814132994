import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'

export const useAssetsMetaListDataItems = () => {
  const assetsMetaListDataItems = useSelector<
    AppState,
    AssetsMetaListDataItem[]
  >(state => state.assetsMetaList.data ?? [])

  return assetsMetaListDataItems
}
