import { CardSection, Form, Link } from 'components'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { data } from './register-data'
import { useRegister } from './useRegister'

export const Register = () => {
  const history = useHistory()

  const { handleSubmit, loading, formError } = useRegister()

  return (
    <CardSection
      title={data.title}
      description={data.description}
      cardProps={{
        title: data.title,
        extra: <Link onClick={() => history.push('/')}>Signin</Link>
      }}
    >
      <Form
        {...data.registerFormProps}
        onFinish={handleSubmit}
        loading={loading}
        formError={formError}
      />
    </CardSection>
  )
}
