import { AssetMeta } from '@poem/pam-utils'
import { Select } from 'antd'
import capitalize from 'capitalize'
import { FormProps, Table } from 'components'
import React from 'react'
import { getAssetsDataSource } from './assets'
import { CategoryOrLocation, Option } from './interfaces'

/**
 *
 * @param name "{Name} {verb} successfully"
 * @param verb "{Name} {verb} successfully"
 * @param redirect page to redirect to after pressing continue
 * @param pageName name of the page to redirect to after pressing continue
 */
export const getSuccessStep = (
  name: string,
  verb: string,
  redirect: string,
  pageName: string
) => ({
  type: 'result' as 'result',
  title: 'Done',
  description: `Successfully ${verb}!`,
  resultProps: {
    status: 'success' as 'success',
    redirect,
    title: `${capitalize(name)} ${verb} successfully`,
    subTitle: `Click continue to return to ${pageName}`
  }
})

export const moveFormProps = (
  type: CategoryOrLocation,
  affectedAssets: AssetMeta[],
  options: Option[]
): FormProps => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Category',
      dataIndex: 'category'
    },
    {
      title: 'Location',
      dataIndex: 'location'
    }
  ]
  const dataSource = getAssetsDataSource(affectedAssets)

  return {
    layout: 'vertical',
    items: [
      {
        children: (
          <>
            <div>Move these assets to a new category</div>
            <br />
            <Table
              columns={columns}
              dataSource={dataSource}
              selectable={false}
            />
          </>
        )
      },
      {
        name: 'newGroup',
        label: `New ${type}`,
        rules: [{ required: true }],
        children: (
          <Select>
            {options.map((option, i) => (
              <Select.Option key={i} value={option.value}>
                {option.text}
              </Select.Option>
            ))}
          </Select>
        )
      }
    ]
  }
}
