import { AssetIndexDoc, AssetMeta, AssetsMetaDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import { errors, StandardReturn } from 'utils'
import { getAssetRef } from './deleteAsset'

async function isAssetIdAvailable(companyRef: string, assetId: string) {
  const doc = await firebase
    .firestore()
    .doc(`companies/${companyRef}/assetIndex/${assetId}`)
    .get()

  return !doc.exists
}

/**
 *
 * @param assetsMetaItems
 * @param assetMetas id cannot change
 */
function getNewAssetsMetaDocs(
  assetsMetaItems: AssetsMetaListDataItem[],
  assetMetas: AssetMeta[]
): { doc: AssetsMetaDoc; id: string }[] {
  const assetMetasMap: { [id: string]: AssetMeta } = {}
  for (const assetMeta of assetMetas) {
    assetMetasMap[assetMeta.id] = assetMeta
  }

  const newAssetsMetaDocs: { doc: AssetsMetaDoc; id: string }[] = []

  for (const item of assetsMetaItems) {
    // find all assets in this item and remove them from doc
    let containAsset = false
    let newAssets = [...item.doc.assets]

    for (let i = 0; i < newAssets.length; i++) {
      const am = newAssets[i]

      if (assetMetasMap[am.id]) {
        containAsset = true
        newAssets[i] = assetMetasMap[am.id]
      }
    }

    if (containAsset) {
      let newAssetsMetaDoc = { assets: newAssets }
      newAssetsMetaDocs.push({ doc: newAssetsMetaDoc, id: item.id })
    }
  }

  return newAssetsMetaDocs
}

function getNewAssetsMetaDoc(
  assetsMetaItems: AssetsMetaListDataItem[],
  newAssetMeta: AssetMeta,
  oldId: string
): { newAssetsMetaDoc: AssetsMetaDoc; id: string } {
  for (const item of assetsMetaItems) {
    for (let i = 0; i < item.doc.assets.length; i++) {
      const assetMeta = item.doc.assets[i]
      if (assetMeta.id === oldId) {
        const newAssets = [...item.doc.assets]
        newAssets[i] = newAssetMeta

        const newAssetsMetaDoc = {
          assets: newAssets
        }

        return {
          id: item.id,
          newAssetsMetaDoc
        }
      }
    }
  }

  throw Error(`Invalid input - Can't find assetMeta in assetsMetaItems`)
}

export function batchUpdateAssetsMetaDoc(
  batch: firebase.firestore.WriteBatch,
  companyRef: string,
  id: string,
  newAssetsMetaDoc: AssetsMetaDoc
) {
  const db = firebase.firestore()

  const assetsMetaDocRef = db.doc(`companies/${companyRef}/assetsMeta/${id}`)
  batch.update(assetsMetaDocRef, newAssetsMetaDoc)
}

function batchUpdateAssetAndAssetIndexDoc(
  batch: firebase.firestore.WriteBatch,
  companyRef: string,
  assetRef: string,
  oldAssetId: string,
  newAssetMeta: AssetMeta
) {
  const db = firebase.firestore()

  // update AssetDoc
  const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
  batch.update(assetDocRef, newAssetMeta)

  if (oldAssetId !== newAssetMeta.id) {
    // delete old AssetIndexDoc
    const oldAssetIndexDocRef = db.doc(
      `companies/${companyRef}/assetIndex/${oldAssetId}`
    )
    batch.delete(oldAssetIndexDocRef)

    // create new AssetIndexDoc
    const newAssetIndexDocRef = db.doc(
      `companies/${companyRef}/assetIndex/${newAssetMeta.id}`
    )
    const newAssetIndexDoc: AssetIndexDoc = {
      assetRef
    }
    batch.set(newAssetIndexDocRef, newAssetIndexDoc)
  }
}

async function updateAssetDocs(
  companyRef: string,
  assetRef: string,
  oldAssetId: string,
  newAssetMeta: AssetMeta,
  assetsMetaItems: AssetsMetaListDataItem[]
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update in AssetsMetaDoc
  const { newAssetsMetaDoc, id } = getNewAssetsMetaDoc(
    assetsMetaItems,
    newAssetMeta,
    oldAssetId
  )

  batchUpdateAssetsMetaDoc(batch, companyRef, id, newAssetsMetaDoc)
  batchUpdateAssetAndAssetIndexDoc(
    batch,
    companyRef,
    assetRef,
    oldAssetId,
    newAssetMeta
  )

  await batch.commit()
}

export async function updateAsset(
  companyRef: string,
  oldAssetId: string,
  newAssetMeta: AssetMeta,
  assetsMetaItems: AssetsMetaListDataItem[]
): Promise<StandardReturn> {
  const assetRef = await getAssetRef(companyRef, oldAssetId)

  if (oldAssetId !== newAssetMeta.id) {
    const assetIdAvailable = await isAssetIdAvailable(
      companyRef,
      newAssetMeta.id
    )

    if (!assetIdAvailable) {
      return { success: false, error: errors.notAvailable('asset', 'id') }
    }
  }

  try {
    await updateAssetDocs(
      companyRef,
      assetRef,
      oldAssetId,
      newAssetMeta,
      assetsMetaItems
    )
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}

/**
 *
 * @param companyRef
 * @param assetMetas id must not change
 * @param assetsMetaItems
 */
export async function updateAssets(
  companyRef: string,
  assetMetas: AssetMeta[],
  assetsMetaItems: AssetsMetaListDataItem[]
): Promise<StandardReturn> {
  try {
    const assetRefPromises = assetMetas.map(assetMeta =>
      getAssetRef(companyRef, assetMeta.id)
    )
    const assetRefs = await Promise.all(assetRefPromises)
    const db = firebase.firestore()

    const promises = []

    // update AssetDoc, AssetIndexDoc
    for (let i = 0; i < assetMetas.length; i++) {
      const batch = db.batch()

      batchUpdateAssetAndAssetIndexDoc(
        batch,
        companyRef,
        assetRefs[i],
        assetMetas[i].id,
        assetMetas[i]
      )

      promises.push(batch.commit())
    }

    const newAssetsMetaDocs = getNewAssetsMetaDocs(assetsMetaItems, assetMetas)

    // update AssetsMetaDocs
    for (const newAssetsMetaDoc of newAssetsMetaDocs) {
      const batch = db.batch()
      batchUpdateAssetsMetaDoc(
        batch,
        companyRef,
        newAssetsMetaDoc.id,
        newAssetsMetaDoc.doc
      )

      promises.push(batch.commit())
    }

    await Promise.all(promises)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
