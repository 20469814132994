import { getCompanyRef } from 'actions/utils'
import {
  UpdateListAction,
  updateListActionType,
  UpdateUnsubscribeFnAction,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { AppState } from 'reducers/appState'
import { ThunkAction } from 'redux-thunk'

type SubscribeToList<DocType> = () => ThunkAction<
  void,
  AppState,
  void,
  | UpdateListAction<updateListActionType, DocType>
  | UpdateUnsubscribeFnAction<updateUnsubscribeFnActionType>
>

export const subscribeToListFactory = <DocType>(
  listName:
    | 'user'
    | 'userList'
    | 'company'
    | 'categoryList'
    | 'locationList'
    | 'assetList'
    | 'assetsMetaList',
  collectionName: string,
  updateListActionType: updateListActionType,
  updateUnsubscribeFnActionType: updateUnsubscribeFnActionType,
  filter?: (
    collectionRef: firebase.firestore.CollectionReference<
      firebase.firestore.DocumentData
    >,
    getState: () => AppState
  ) => Promise<firebase.firestore.Query<firebase.firestore.DocumentData>>
): SubscribeToList<DocType> => () => async (dispatch, getState) => {
  // only establish listeners of not already listening
  if (getState()[listName].unsubscribe) return

  const currentUser = firebase.auth().currentUser
  const companyRef = await getCompanyRef(getState)

  if (!currentUser || !companyRef) return

  // subscribe
  let collectionRef:
    | firebase.firestore.Query<firebase.firestore.DocumentData>
    | firebase.firestore.CollectionReference<
        firebase.firestore.DocumentData
      > = firebase
    .firestore()
    .collection(`companies/${companyRef}/${collectionName}`)

  if (filter) {
    collectionRef = await filter(
      collectionRef as firebase.firestore.CollectionReference<
        firebase.firestore.DocumentData
      >,
      getState
    )
  }

  const unsubscribe = collectionRef.onSnapshot(snapshot => {
    const data = snapshot.docs.map(doc => ({
      id: doc.id,
      doc: doc.data() as DocType
    }))

    dispatch({
      type: updateListActionType,
      data
    })
  })

  dispatch({
    type: updateUnsubscribeFnActionType,
    data: unsubscribe
  })
}
