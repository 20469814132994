import {
  subscribeToCategoryList,
  subscribeToCompany,
  subscribeToLocationList,
  subscribeToUser,
  subscribeToUserList
} from 'actions'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { register, RegisterFormData } from 'functions'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

export const useRegister = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')

  const handleSubmit = useCallback(
    async (values: any) => {
      if (loading) return

      setLoading(true)
      setFormError('')

      const { user } = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      if (!user?.email) {
        // set form error
        return
      }

      const newCompanyDoc: RegisterFormData = {
        companyName: values.name,
        currency: values.currency,
        dateTimeFormat: 'dd/MM/yyyy',
        timezone: 'Asia/Bangkok'
      }

      const res = await register(newCompanyDoc, { email: user.email })
      dispatch(subscribeToUser())
      dispatch(subscribeToCompany())
      dispatch(subscribeToUserList())
      dispatch(subscribeToLocationList())
      dispatch(subscribeToCategoryList())

      if (!res.success) {
        setFormError(res.error)
      }
    },
    [dispatch, loading]
  )

  return {
    loading,
    formError,
    handleSubmit
  }
}
