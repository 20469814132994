import { useGroups } from 'hooks/useGroups'
import { useMemo } from 'react'
import { CategoryOrLocation } from 'utils'

export const useGroupsMap = (type: CategoryOrLocation) => {
  const groups = useGroups(type)
  return useMemo(() => {
    const map: { [group: string]: true } = {}
    for (const group of groups) {
      map[group] = true
    }
    return map
  }, [groups])
}
