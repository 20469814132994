import validator from 'validator'
import { errors } from './const'

export const validDocumentName = (value: string, name: string) => {
  if (value === '.' || value === '..' || value === '__.*__')
    return errors.cannotEqual(name, '., .. or __.*__')
  if (!validator.isAlphanumeric(validator.blacklist(value, '-_. ')))
    return errors.alplaNumeric(name)
  if (value.length > 1000) return errors.tooLong
  return null
}
