import {
  UpdateUnsubscribeFnAction,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/firestore'
import { AppState } from 'reducers/appState'
import { ThunkAction } from 'redux-thunk'

type Unsubscribe = () => ThunkAction<
  void,
  AppState,
  void,
  UpdateUnsubscribeFnAction<updateUnsubscribeFnActionType>
>

export const unsubscribeFactory = (
  name:
    | 'user'
    | 'userList'
    | 'company'
    | 'categoryList'
    | 'locationList'
    | 'assetList'
    | 'assetsMetaList',
  updateUnsubscribeFnActionType: updateUnsubscribeFnActionType
): Unsubscribe => () => async (dispatch, getState) => {
  const { unsubscribe } = getState()[name]

  // only unsubscribe if currently listening
  if (!unsubscribe) return

  unsubscribe()

  dispatch({
    type: updateUnsubscribeFnActionType,
    data: null
  })
}
