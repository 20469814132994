import {
  AssetsMetaDoc,
  CompanyDoc,
  CompanyIndexDoc,
  UserCompanyMapDoc,
  UserDoc,
  UserRole
} from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'
import { v4 as uuid } from 'uuid'

export interface RegisterFormData {
  companyName: string
  currency: string
  timezone: string
  dateTimeFormat: string
}

interface User {
  email: string
}

export async function createCompanyDocs(values: RegisterFormData, user: User) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create CompanyIndexDoc
  const companyRef = uuid()
  const companyIndexDocRef = db.doc(`companyIndex/${values.companyName}`)
  const companyIndexDoc: CompanyIndexDoc = {
    companyRef
  }
  batch.set(companyIndexDocRef, companyIndexDoc)

  // create CompanyDoc
  const companyDocRef = db.doc(`companies/${companyRef}`)
  const companyDoc: CompanyDoc = {
    currency: values.currency,
    name: values.companyName,
    dateAndTimeFormat: values.dateTimeFormat,
    logoUrl: '',
    timezone: values.timezone
  }
  batch.set(companyDocRef, companyDoc)

  // create UserDoc
  const userDocRef = db.doc(`companies/${companyRef}/users/${user.email}`)
  const userDoc: UserDoc = {
    requests: [],
    companyRef,
    email: user.email,
    name: '',
    role: UserRole.admin
  }
  batch.set(userDocRef, userDoc)

  // create UserCompanyMap
  const userCompanyMapRef = db.doc(`userCompanyMap/${user.email}`)
  const userCompanyMapDoc: UserCompanyMapDoc = {
    companyRef
  }
  batch.set(userCompanyMapRef, userCompanyMapDoc)

  // create AssetsMetaDoc
  const assetsMetaRef = db
    .collection(`companies/${companyRef}/assetsMeta`)
    .doc()
  const assetsMetaDoc: AssetsMetaDoc = {
    assets: []
  }
  batch.set(assetsMetaRef, assetsMetaDoc)

  await batch.commit()
}

export async function isCompanyNameAvailable(companyName: string) {
  const doc = await firebase
    .firestore()
    .doc(`companyIndex/${companyName}`)
    .get()
  return !doc.exists
}

export async function isPartOfCompany(email: string) {
  const doc = await firebase.firestore().doc(`userCompanyMap/${email}`).get()
  return doc.exists
}

export async function register(
  values: RegisterFormData,
  user: User
): Promise<StandardReturn> {
  const [partOfCompany, companyNameAvailable] = await Promise.all([
    isPartOfCompany(user.email),
    isCompanyNameAvailable(values.companyName)
  ])

  if (partOfCompany) {
    return {
      success: false,
      error: errors.cannotRegisterAdminAccountAlreadyPartOfAnotherCompany
    }
  }

  if (!companyNameAvailable) {
    return {
      success: false,
      error: errors.companyNameNotAvailable
    }
  }

  try {
    await createCompanyDocs(values, user)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
