import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'

export const useCompanyRef = () => {
  const companyRef = useSelector<AppState, string>(
    state => state.user.data?.companyRef ?? ''
  )

  return companyRef
}
