import {
  updateAssetPageActiveAssetsTablePageActionType,
  updateAssetPageExpiredAssetsTablePageActionType
} from 'actionTypes'
import { assetPageReducerFactory } from './factories'

export const allAssetPage = assetPageReducerFactory(
  updateAssetPageActiveAssetsTablePageActionType.updateAllAssetsPageActiveAssetsTablePage,
  updateAssetPageExpiredAssetsTablePageActionType.updateAllAssetsPageExpiredAssetsTablePage
)
