import { AssetDoc } from '@poem/pam-utils'
import { TableSection } from 'components'
import moment from 'moment-timezone'
import React from 'react'

interface CustodianHistoryProps {
  loading?: boolean
  asset?: AssetDoc
}

export const CustodianHistory = ({ loading, asset }: CustodianHistoryProps) => {
  const custodianHistoryColumns = [
    {
      title: 'Date',
      dataIndex: 'date'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    }
  ]

  const custodianHistoryDataSource = asset
    ? asset.custodianHistory.map(value => ({
        date: moment(value.assignedTime).calendar(),
        email: value.email
      }))
    : []

  return (
    <TableSection
      title="Custodian History"
      description="Record of the asset's custodians"
      tableProps={{
        title: 'Custodian History',
        loading: loading,
        columns: custodianHistoryColumns,
        dataSource: custodianHistoryDataSource,
        showExtras: false,
        selectable: false
      }}
    />
  )
}
