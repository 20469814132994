import { AssetIndexDoc, AssetMeta, AssetsMetaDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import { remove } from 'lodash'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import { errors, firebaseFunctionName, StandardReturn } from 'utils'

function getNewAssetsMetaDocs(
  assetMetas: AssetMeta[],
  assetsMetaItems: AssetsMetaListDataItem[]
): { doc: AssetsMetaDoc; id: string }[] {
  const assetMetasMap: { [id: string]: AssetMeta } = {}
  for (const assetMeta of assetMetas) {
    assetMetasMap[assetMeta.id] = assetMeta
  }

  const newAssetsMetaDocs: { doc: AssetsMetaDoc; id: string }[] = []

  for (const item of assetsMetaItems) {
    // find all assets in this item and remove them from doc
    let containAsset = false
    let newAssets = [...item.doc.assets]

    for (const am of item.doc.assets) {
      if (assetMetasMap[am.id]) {
        containAsset = true
        remove(newAssets, am)
      }
    }

    if (containAsset) {
      let newAssetsMetaDoc = { assets: newAssets }
      newAssetsMetaDocs.push({ doc: newAssetsMetaDoc, id: item.id })
    }
  }

  return newAssetsMetaDocs
}

function getNewAssetsMetaDoc(
  assetMeta: AssetMeta,
  assetsMetaItems: AssetsMetaListDataItem[]
): { newAssetsMetaDoc: AssetsMetaDoc; id: string } {
  for (const item of assetsMetaItems) {
    for (const _assetMeta of item.doc.assets) {
      if (assetMeta.id === _assetMeta.id) {
        const newAssets = [...item.doc.assets]
        remove(newAssets, assetMeta)

        const newAssetsMetaDoc = {
          assets: newAssets
        }

        return {
          id: item.id,
          newAssetsMetaDoc
        }
      }
    }
  }

  throw Error(`Invalid input - Can't find assetMeta in assetsMetaItems`)
}

function batchDeleteAssetAndAssetIndexDoc(
  batch: firebase.firestore.WriteBatch,
  companyRef: string,
  assetRef: string,
  assetId: string
) {
  const db = firebase.firestore()

  // delete AssetDoc
  const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
  batch.delete(assetDocRef)

  // delete AssetIndexDoc
  const assetIndexDocRef = db.doc(
    `companies/${companyRef}/assetIndex/${assetId}`
  )
  batch.delete(assetIndexDocRef)
}

function batchDeleteAssetsMetaDoc(
  batch: firebase.firestore.WriteBatch,
  companyRef: string,
  assetId: string,
  newAssetsMetaDoc: AssetsMetaDoc
) {
  const db = firebase.firestore()

  const assetsMetaDocRef = db.doc(
    `companies/${companyRef}/assetsMeta/${assetId}`
  )
  if (newAssetsMetaDoc.assets.length === 0) {
    batch.delete(assetsMetaDocRef)
  } else {
    batch.update(assetsMetaDocRef, newAssetsMetaDoc)
  }
}

async function deleteAssetDocs(
  companyRef: string,
  assetMeta: AssetMeta,
  assetsMetaDocs: AssetsMetaListDataItem[],
  assetRef: string
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // find and delete from asset metas
  const { id, newAssetsMetaDoc } = getNewAssetsMetaDoc(
    assetMeta,
    assetsMetaDocs
  )
  batchDeleteAssetsMetaDoc(batch, companyRef, id, newAssetsMetaDoc)

  batchDeleteAssetAndAssetIndexDoc(batch, companyRef, assetRef, assetMeta.id)

  await batch.commit()
}

async function deleteAssetPics(companyRef: string, assetRef: string) {
  const deleteStorageFolder = firebase
    .functions()
    .httpsCallable(firebaseFunctionName.deleteStorageFolder)
  const ref = `companies/${companyRef}/assets/${assetRef}`

  await deleteStorageFolder({ ref })
}

export async function getAssetRef(companyRef: string, assetId: string) {
  // get assetRef
  const assetIndexDoc = await firebase
    .firestore()
    .doc(`companies/${companyRef}/assetIndex/${assetId}`)
    .get()
  const assetIndexDocData = assetIndexDoc.data() as AssetIndexDoc
  return assetIndexDocData.assetRef
}

export async function deleteAsset(
  companyRef: string,
  assetMeta: AssetMeta,
  assetsMetaItems: AssetsMetaListDataItem[]
): Promise<StandardReturn> {
  try {
    const assetRef = await getAssetRef(companyRef, assetMeta.id)

    await deleteAssetDocs(companyRef, assetMeta, assetsMetaItems, assetRef)
    await deleteAssetPics(companyRef, assetRef)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}

export async function deleteAssets(
  companyRef: string,
  assetMetas: AssetMeta[],
  assetsMetaItems: AssetsMetaListDataItem[]
): Promise<StandardReturn> {
  try {
    const assetRefPromises = assetMetas.map(assetMeta =>
      getAssetRef(companyRef, assetMeta.id)
    )
    const assetRefs = await Promise.all(assetRefPromises)
    const db = firebase.firestore()

    const promises = []

    // delete AssetDoc, AssetIndexDoc, and asset's pictures
    for (let i = 0; i < assetMetas.length; i++) {
      const batch = db.batch()

      batchDeleteAssetAndAssetIndexDoc(
        batch,
        companyRef,
        assetRefs[i],
        assetMetas[i].id
      )

      promises.push(batch.commit(), deleteAssetPics(companyRef, assetRefs[i]))
    }

    const newAssetsMetaDocs = getNewAssetsMetaDocs(assetMetas, assetsMetaItems)

    for (const newAssetsMetaDoc of newAssetsMetaDocs) {
      const batch = db.batch()
      batchDeleteAssetsMetaDoc(
        batch,
        companyRef,
        newAssetsMetaDoc.id,
        newAssetsMetaDoc.doc
      )
      promises.push(batch.commit())
    }

    await Promise.all(promises)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
