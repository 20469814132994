import { AssetMeta } from '@poem/pam-utils'
import { Act, MultiStepForm } from 'components'
import { createAssets, updateAssets as updateAssetsFn } from 'functions'
import {
  useAssetMetasMap,
  useAssetsMetaListDataItems,
  useCompany,
  useCompanyRef,
  useGroupsMap
} from 'hooks'
import React, { useCallback, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import { errors, ExcelError, isExpired } from 'utils'
import { bulkAddUpdateAssetsData } from './bulk-add-update-assets-data'
import {
  Asset,
  AssetChanges,
  AssetErrors
} from './bulk-add-update-assets-interfaces'
import {
  assetFileSchema,
  getAssetUpdates,
  validateAsset
} from './bulk-add-update-assets-utils'

export const BulkAddUpdateAssets = () => {
  const company = useCompany()
  const companyRef = useCompanyRef()
  const assetsMetaListDataItems = useAssetsMetaListDataItems()
  const categoriesMap = useGroupsMap('category')
  const locationsMap = useGroupsMap('location')
  const activeAssetsMap = useAssetMetasMap(
    asset => !isExpired(asset.disposalDate)
  )
  const expiredAssetsMap = useAssetMetasMap(asset =>
    isExpired(asset.disposalDate)
  )
  const [newAssets, setNewAssets] = useState<Asset[]>([])
  const [updateAssets, setUpdateAssets] = useState<AssetChanges[]>([])
  const [errorAssets, setErrorAssets] = useState<AssetErrors[]>([])

  const handleAssetFileSubmitFn: Act = async (values, i) => {
    let rows: Asset[] = []
    let errors: ExcelError[] = []

    // parse excel file
    try {
      const res = await readXlsxFile(values.assetFile.file.originFileObj, {
        schema: assetFileSchema
      })
      rows = res.rows
      errors = res.errors
    } catch (error) {
      console.error(error)
      return {
        res: {
          success: false,
          error: 'Unable to parse this file.'
        },
        goToStep: i
      }
    }

    // check if there are parsing errors
    if (errors.length) {
      return {
        res: {
          success: false,
          error: `Parsing Error: Column: ${errors[0].column} | Row: ${errors[0].row} | Error: ${errors[0].error} | Cell value: ${errors[0].value}`
        },
        goToStep: 0
      }
    }

    const newNewAssets = []
    const newUpdateAssets = []
    const newErrorAssets = []

    // check that all asset ids are unique
    const uniqueIdsMap: { [id: string]: true } = {}

    for (const asset of rows) {
      if (uniqueIdsMap[asset.id]) {
        return {
          res: {
            success: false,
            error: `This asset's ID is not unique: ${asset.id}.`
          },
          goToStep: 0
        }
      }

      uniqueIdsMap[asset.id] = true
    }

    // validate each assets
    for (const asset of rows) {
      const { valid, errors } = validateAsset(
        asset,
        expiredAssetsMap,
        categoriesMap,
        locationsMap
      )

      if (valid) {
        if (activeAssetsMap[asset.id]) {
          const assetChanges = getAssetUpdates(activeAssetsMap[asset.id], asset)
          if (assetChanges.changes.length) {
            newUpdateAssets.push(assetChanges)
          }
        } else {
          newNewAssets.push(asset)
        }
      } else {
        newErrorAssets.push({ asset, errors })
      }
    }

    // set new/update/error assets
    setNewAssets(newNewAssets)
    setUpdateAssets(newUpdateAssets)
    setErrorAssets(newErrorAssets)

    return { res: { success: true }, goToStep: i + 1 }
  }
  const handleAssetFileSubmit = useCallback(handleAssetFileSubmitFn, [])

  const handleReviewSubmitFn: Act = async (_, i) => {
    const newAssetMetas: AssetMeta[] = newAssets.map(v => ({
      ...v,
      assignee: null,
      currentCustodian: null,
      pendingCustodian: null
    }))
    const updateAssetMetas: AssetMeta[] = updateAssets.map(v => v.newAsset)

    const responses = await Promise.all([
      createAssets(companyRef, newAssetMetas, assetsMetaListDataItems),
      updateAssetsFn(companyRef, updateAssetMetas, assetsMetaListDataItems)
    ])

    if (!responses.every(res => res.success)) {
      return {
        res: { success: false, error: errors.somethingWentWrong },
        goToStep: i
      }
    }

    return { res: { success: true }, goToStep: i + 1 }
  }
  const handleReviewSubmit = useCallback(handleReviewSubmitFn, [
    newAssets,
    updateAssets
  ])

  return (
    <MultiStepForm
      {...bulkAddUpdateAssetsData(
        handleAssetFileSubmit,
        handleReviewSubmit,
        newAssets,
        updateAssets,
        errorAssets,
        company
      )}
    />
  )
}
