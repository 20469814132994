import { AssetMeta } from '@poem/pam-utils'
import { Act, MultiStepFormProps } from 'components'
import {
  CategoryOrLocation,
  getSuccessStep,
  moveFormProps,
  Option,
  routes
} from 'utils'

export const data = (
  type: CategoryOrLocation,
  handleMoveAndDelete: Act,
  affectedAssets: AssetMeta[],
  options: Option[]
): MultiStepFormProps => ({
  title: 'Move and Delete',
  description: `There are some assets in this ${type}. Please choose a new ${type} for these assets before deleting.`,
  steps: [
    {
      type: 'form',
      act: handleMoveAndDelete,
      title: 'Move',
      description: `Move assets to a new ${type} before deleting`,
      formProps: moveFormProps(type, affectedAssets, options)
    },
    getSuccessStep(type, 'removed', routes.settings, 'Settings')
  ]
})
