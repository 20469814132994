import { CompanyDoc } from '@poem/pam-utils'
import { Act, FileUpload, FormProps, MultiStepFormProps } from 'components'
import {
  Asset,
  AssetChanges,
  AssetErrors
} from 'pages/bulk-add-update-assets/bulk-add-update-assets-interfaces'
import React from 'react'
import { getSuccessStep, routes } from 'utils'
import {
  ErrorAssetsSummary,
  NewAssetsSummary,
  UpdateAssetsSummary
} from './components'

const fileFormProps: FormProps = {
  layout: 'vertical',
  items: [
    {
      name: 'assetFile',
      label: 'Assets File',
      rules: [{ required: true }],
      children: (
        <FileUpload accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
      )
    }
  ]
}

const reviewFormProps = (
  newAssets: Asset[],
  updateAssets: AssetChanges[],
  errorAssets: AssetErrors[],
  company?: CompanyDoc
): FormProps => ({
  layout: 'vertical',
  items: [
    {
      children: (
        <>
          <NewAssetsSummary assets={newAssets} company={company} />
          <br />
          <UpdateAssetsSummary assetChangesList={updateAssets} />
          <br />
          {Boolean(errorAssets.length) && (
            <ErrorAssetsSummary assetErrors={errorAssets} />
          )}
        </>
      )
    }
  ]
})

export const bulkAddUpdateAssetsData = (
  handleAssetFileSubmit: Act,
  handleReviewSubmit: Act,
  newAssets: Asset[],
  updateAssets: AssetChanges[],
  errorAssets: AssetErrors[],
  company?: CompanyDoc
): MultiStepFormProps => ({
  title: 'Build Add/Update Assets',
  description: (
    <div>
      <p>
        Please upload an assets file and click next to add or update assets.{' '}
        Download an{' '}
        <a href="https://firebasestorage.googleapis.com/v0/b/pam-dev-dc411.appspot.com/o/static%2FAdd-Update%20Assets%20-%20Example%20Form.xlsx?alt=media&token=29f1e879-d240-43cc-ab31-d08db23da86c">
          example asset file
        </a>{' '}
        to get started.
      </p>
    </div>
  ),
  steps: [
    {
      type: 'form',
      title: 'Upload assets file',
      description: 'Upload assets file',
      formProps: fileFormProps,
      act: handleAssetFileSubmit
    },
    {
      type: 'form',
      title: 'Review',
      description: 'Review asset changes',
      formProps: reviewFormProps(newAssets, updateAssets, errorAssets, company),
      act: handleReviewSubmit
    },
    getSuccessStep('Assets', 'updated', routes.allAssets, 'All Assets')
  ]
})
