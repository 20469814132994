import { UserRole } from '@poem/pam-utils'
import {
  AddCategoryLocation,
  AddEditAsset,
  AllAssets,
  Asset,
  AssignCustodian,
  Depreciation,
  EditCategoryLocation,
  MyAssets,
  Profile,
  Register,
  Requests,
  Settings,
  Signin
} from 'pages'
import { AddEditUser } from 'pages/add-edit-user'
import { BulkAddUpdateAssets } from 'pages/bulk-add-update-assets'
import { MoveAssetAndDeleteCategoryLocation } from 'pages/move-asset-and-delete-category-location'
import React from 'react'
import { routes } from 'utils'

export const pagesData: {
  roles: string[]
  path: string
  component: (params: any) => JSX.Element | null
}[] = [
  {
    roles: [
      UserRole.admin,
      UserRole.manager,
      UserRole.auditor,
      UserRole.viewer,
      'all'
    ],
    path: routes.home,
    component: Signin
  },
  {
    roles: [
      UserRole.admin,
      UserRole.manager,
      UserRole.viewer,
      UserRole.auditor,
      'all'
    ],
    path: routes.register,
    component: Register
  },
  {
    roles: [UserRole.admin, UserRole.auditor, UserRole.manager],
    path: routes.allAssets,
    component: AllAssets
  },
  {
    roles: [UserRole.admin, UserRole.manager, UserRole.viewer],
    path: routes.myAssets,
    component: MyAssets
  },
  {
    roles: [
      UserRole.admin,
      UserRole.manager,
      UserRole.auditor,
      UserRole.viewer
    ],
    path: routes.asset,
    component: Asset
  },
  {
    roles: [UserRole.admin, UserRole.manager],
    path: routes.addAsset,
    component: () => <AddEditAsset type="add" />
  },
  {
    roles: [UserRole.admin, UserRole.manager],
    path: routes.editAsset,
    component: ({ location }: any) => (
      <AddEditAsset type="edit" selectedRows={location.state.selectedRows} />
    )
  },
  {
    roles: [UserRole.admin],
    path: routes.settings,
    component: Settings
  },
  {
    roles: [UserRole.admin],
    path: routes.addCategory,
    component: () => <AddCategoryLocation dataType="category" />
  },
  {
    roles: [UserRole.admin],
    path: routes.editCategory,
    component: ({ location }) => (
      <EditCategoryLocation dataType="category" location={location} />
    )
  },
  {
    roles: [UserRole.admin],
    path: routes.addLocation,
    component: () => <AddCategoryLocation dataType="location" />
  },
  {
    roles: [UserRole.admin],
    path: routes.editLocation,
    component: ({ location }) => (
      <EditCategoryLocation dataType="location" location={location} />
    )
  },
  {
    roles: [UserRole.admin],
    path: routes.addUser,
    component: ({ location }) => <AddEditUser type="add" location={location} />
  },
  {
    roles: [UserRole.admin],
    path: routes.editUser,
    component: ({ location }) => <AddEditUser type="edit" location={location} />
  },
  {
    roles: [UserRole.admin, UserRole.manager, UserRole.viewer],
    path: routes.assignCustodian,
    component: AssignCustodian
  },
  {
    roles: [UserRole.admin, UserRole.manager, UserRole.viewer],
    path: routes.requests,
    component: Requests
  },
  {
    roles: [UserRole.admin, UserRole.auditor, UserRole.manager],
    path: routes.depreciation,
    component: Depreciation
  },
  {
    roles: [
      UserRole.admin,
      UserRole.manager,
      UserRole.auditor,
      UserRole.viewer
    ],
    path: routes.profile,
    component: Profile
  },
  {
    roles: [UserRole.admin],
    path: routes.moveAndDeleteCategory,
    component: ({ location }) => (
      <MoveAssetAndDeleteCategoryLocation type="category" location={location} />
    )
  },
  {
    roles: [UserRole.admin],
    path: routes.moveAndDeleteLocation,
    component: ({ location }) => (
      <MoveAssetAndDeleteCategoryLocation type="location" location={location} />
    )
  },
  {
    roles: [UserRole.manager, UserRole.admin],
    path: routes.bulkAddUpdateAssets,
    component: BulkAddUpdateAssets
  }
]
