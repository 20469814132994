import { LocationDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/firestore'
import { subscribeToListFactory } from './factories'

export const subscribeToLocationList = subscribeToListFactory<LocationDoc>(
  'locationList',
  'locations',
  updateListActionType.updateLocationList,
  updateUnsubscribeFnActionType.updateLocationListUnsubscribeFn
)
