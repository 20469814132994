import { AssetCustodianChangeRequest } from '@poem/pam-utils'
import { Button, message } from 'antd'
import { TableSection } from 'components'
import firebase from 'firebase/app'
import 'firebase/auth'
import {
  custodianChangeRequestsAccept,
  custodianChangeRequestsCancel
} from 'functions'
import { useAssetsMetaListDataItems, useUser } from 'hooks'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers'
import { errors } from 'utils'

interface RequestDataSource extends AssetCustodianChangeRequest {
  key: string
}

export const Requests = () => {
  const user = useUser()
  const assetMetasListDataItems = useAssetsMetaListDataItems()
  const currentUserEmail = firebase.auth().currentUser?.email
  const loading = useSelector<AppState, boolean>(state =>
    Boolean(state.assetList.loading)
  )

  const [cancelByYouLoading, setCancelByYouLoading] = useState(false)
  const [acceptToYouLoading, setAcceptToYouLoading] = useState(false)
  const [cancelToYouLoading, setCancelToYouLoading] = useState(false)

  const handleAccept = useCallback(
    async (
      selectedRows: RequestDataSource[],
      acceptLoading: boolean,
      setAcceptLoading: Dispatch<SetStateAction<boolean>>
    ) => {
      if (acceptLoading || !user) return

      setAcceptLoading(true)

      const requests: AssetCustodianChangeRequest[] = [...selectedRows]
      requests.forEach(
        request => delete (request as Partial<RequestDataSource>).key
      )

      try {
        const res = await custodianChangeRequestsAccept(
          user.companyRef,
          assetMetasListDataItems,
          requests
        )

        if (res.success) {
          message.success('Successfully updated')
        } else {
          message.error(res.error)
        }
      } catch (error) {
        console.error(error)
        message.error(errors.somethingWentWrong)
      }

      setAcceptLoading(false)
    },
    [assetMetasListDataItems, user]
  )

  const handleCancel = useCallback(
    async (
      selectedRows: RequestDataSource[],
      cancelLoading: boolean,
      setCancelLoading: Dispatch<SetStateAction<boolean>>
    ) => {
      if (cancelLoading || !user) return

      setCancelLoading(true)

      const requests: AssetCustodianChangeRequest[] = [...selectedRows]
      requests.forEach(
        request => delete (request as Partial<RequestDataSource>).key
      )

      try {
        const res = await custodianChangeRequestsCancel(
          user.companyRef,
          assetMetasListDataItems,
          requests,
          user.email
        )

        if (!res.success) {
          message.error(res.error)
        } else {
          message.success('Successfully updated')
        }
      } catch (error) {
        console.error(error)
        message.error(errors.somethingWentWrong)
      }

      setCancelLoading(false)
    },
    [assetMetasListDataItems, user]
  )

  if (!user) return null

  const { requests } = user
  const requestsAssignedByYou = requests.filter(
    request => request.assignee === currentUserEmail
  )
  const requestsAssignedToYou = requests.filter(
    request => request.pendingCustodian === currentUserEmail
  )

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'description'
    },
    {
      title: 'Current Custodian',
      dataIndex: 'currentCustodian'
    },
    {
      title: 'New Custodian',
      dataIndex: 'pendingCustodian'
    }
  ]

  const toYouDataSource: RequestDataSource[] = requestsAssignedToYou.map(
    request => ({
      ...request,
      key: request.id
    })
  )
  const byYouDataSource: RequestDataSource[] = requestsAssignedByYou.map(
    request => ({
      ...request,
      key: request.id
    })
  )

  return (
    <>
      <TableSection
        title="Assigned To You"
        description="Requests assigned to you"
        tableProps={{
          title: 'Assigned To You',
          loading: loading,
          showExtras: false,
          extras: ({ selectedRows }) => (
            <>
              <Button
                disabled={!selectedRows.length}
                onClick={() =>
                  handleAccept(
                    selectedRows,
                    acceptToYouLoading,
                    setAcceptToYouLoading
                  )
                }
                loading={acceptToYouLoading}
              >
                Accept
              </Button>
              <Button
                disabled={!selectedRows.length}
                onClick={() =>
                  handleCancel(
                    selectedRows,
                    cancelToYouLoading,
                    setCancelToYouLoading
                  )
                }
                loading={cancelToYouLoading}
              >
                Cancel
              </Button>
            </>
          ),
          columns,
          dataSource: toYouDataSource
        }}
      />
      <TableSection
        title="Assigned By You"
        description="Requests assigned by you"
        tableProps={{
          title: 'Assigned By You',
          loading: loading,
          showExtras: false,
          extras: ({ selectedRows }) => (
            <Button
              disabled={!selectedRows.length}
              onClick={() =>
                handleCancel(
                  selectedRows,
                  cancelByYouLoading,
                  setCancelByYouLoading
                )
              }
              loading={cancelByYouLoading}
            >
              Cancel
            </Button>
          ),
          columns,
          dataSource: byYouDataSource
        }}
      />
    </>
  )
}
