import { LocationDoc, LocationIndexDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'
import { isLocationNameAvailable } from './createLocation'

async function updateLocationDocs(
  companyRef: string,
  locationRef: string,
  oldLocationName: string,
  newLocationDoc: LocationDoc
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update LocationDoc
  const locationDocRef = db.doc(
    `companies/${companyRef}/locations/${locationRef}`
  )
  batch.set(locationDocRef, newLocationDoc)

  if (oldLocationName !== newLocationDoc.name) {
    // delete old LocationIndexDoc
    const oldLocationIndexDocRef = db.doc(
      `companies/${companyRef}/locationIndex/${oldLocationName}`
    )
    batch.delete(oldLocationIndexDocRef)

    // create new LocationIndexDoc
    const newLocationIndexDocRef = db.doc(
      `companies/${companyRef}/locationIndex/${newLocationDoc.name}`
    )
    const newLocationIndexDoc: LocationIndexDoc = {
      locationRef
    }
    batch.set(newLocationIndexDocRef, newLocationIndexDoc)
  }

  await batch.commit()
}

export async function updateLocation(
  companyRef: string,
  locationRef: string,
  oldLocationName: string,
  newLocationDoc: LocationDoc
): Promise<StandardReturn> {
  if (oldLocationName !== newLocationDoc.name) {
    const locationNameAvailable = await isLocationNameAvailable(
      companyRef,
      newLocationDoc.name
    )

    if (!locationNameAvailable) {
      return { success: false, error: errors.notAvailable('location', 'name') }
    }
  }

  try {
    await updateLocationDocs(
      companyRef,
      locationRef,
      oldLocationName,
      newLocationDoc
    )
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
