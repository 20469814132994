import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'

async function deleteUserDocs(companyRef: string, email: string) {
  const db = firebase.firestore()
  const batch = db.batch()

  // delete UserDoc
  const userDocRef = db.doc(`companies/${companyRef}/users/${email}`)
  batch.delete(userDocRef)

  // delete UserCompanyMap
  const userCompanyMap = db.doc(`userCompanyMap/${email}`)
  batch.delete(userCompanyMap)

  await batch.commit()
}

export async function deleteUser(
  companyRef: string,
  email: string
): Promise<StandardReturn> {
  try {
    await deleteUserDocs(companyRef, email)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
