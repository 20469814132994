import { message } from 'antd'
import { TableSection } from 'components'
import {
  useAssetsMetaListDataItems,
  useDeleteCateogoryLocation,
  useGroupListDataItems
} from 'hooks'
import { capitalize } from 'lodash'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { CategoryListDataItem, LocationListDataItem } from 'reducers'
import { CategoryOrLocation, errors, routes, StandardReturn } from 'utils'
import { data } from './category-location-list-data'

interface CategoryLocationListProps {
  type: CategoryOrLocation
}

export const CategoryLocationList = ({ type }: CategoryLocationListProps) => {
  const history = useHistory()
  const source = useGroupListDataItems(type).sort((a, b) => {
    if (a.doc.name < b.doc.name) return -1
    else if (a.doc.name > b.doc.name) return 1
    return 0
  })
  const assetsMetaListDataItems = useAssetsMetaListDataItems()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    }
  ]

  const dataSource = useMemo(
    () =>
      source.map(value => ({
        key: value.doc.name,
        name: value.doc.name,
        value
      })),
    [source]
  )

  const deleteCategoryLocation = useDeleteCateogoryLocation(type)

  const handleDelete = useCallback(
    async ({
      selectedRows
    }: {
      selectedRows: any[]
    }): Promise<StandardReturn> => {
      const items = selectedRows.map(
        row => row.value as CategoryListDataItem | LocationListDataItem
      )

      const assets = assetsMetaListDataItems.map(item => item.doc.assets).flat()
      const names = items.map(item => item.doc.name)
      const affectedAssets = assets.filter(asset => names.includes(asset[type]))

      if (affectedAssets.length) {
        history.push(
          type === 'category'
            ? routes.moveAndDeleteCategory
            : routes.moveAndDeleteLocation,
          { affectedAssets, items }
        )

        return { success: true }
      } else {
        const res = await deleteCategoryLocation(items)

        if (res.success) {
          message.success(
            `${capitalize(pluralize(type))} deleted successfully.`
          )
        } else {
          message.error(errors.somethingWentWrong)
        }

        return res
      }
    },
    [assetsMetaListDataItems, deleteCategoryLocation, history, type]
  )

  return (
    <TableSection
      title={data.title(type)}
      description={data.description(type)}
      tableProps={{
        title: data.title(type),
        addRoute: `/dashboard/add-${type}`,
        editRoute: `/dashboard/edit-${type}`,
        columns,
        dataSource,
        loading: !Boolean(source),
        handleDelete,
        showDeleteMessage: false
      }}
    />
  )
}
