import { UserDoc } from '@poem/pam-utils'
import {
  updateDocActionType,
  updateUnsubscribeFnActionType,
  UpdateUserAction,
  UpdateUserUnsubscribeFnAction
} from 'actionTypes'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { AppState } from 'reducers/appState'
import { ThunkAction } from 'redux-thunk'
import { subscribeToDocFactory } from './factories'
import { getCompanyRef } from './utils'

type SubscribeToUser = () => ThunkAction<
  void,
  AppState,
  void,
  UpdateUserAction | UpdateUserUnsubscribeFnAction
>

export const subscribeToUser: SubscribeToUser = () => async (
  dispatch,
  getState
) => {
  const currentUser = firebase.auth().currentUser
  const companyRef = await getCompanyRef(getState)

  if (!currentUser || !companyRef) return

  const subscribe = subscribeToDocFactory<UserDoc>(
    'user',
    `companies/${companyRef}/users/${currentUser.email}`,
    updateDocActionType.updateUser,
    updateUnsubscribeFnActionType.updateUserUnsubscribeFn
  )

  dispatch(subscribe())
}
