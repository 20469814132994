import {
  UpdateDocAction,
  updateDocActionType,
  UpdateUnsubscribeFnAction,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { AppState } from 'reducers/appState'
import { ThunkAction } from 'redux-thunk'

type SubscribeToDoc<DocType> = () => ThunkAction<
  void,
  AppState,
  void,
  | UpdateDocAction<updateDocActionType, DocType>
  | UpdateUnsubscribeFnAction<updateUnsubscribeFnActionType>
>

export const subscribeToDocFactory = <DocType>(
  docName:
    | 'user'
    | 'userList'
    | 'company'
    | 'categoryList'
    | 'locationList'
    | 'assetList'
    | 'assetsMetaList',
  docPath: string,
  updateDocActionType: updateDocActionType,
  updateUnsubscribeFnActionType: updateUnsubscribeFnActionType
): SubscribeToDoc<DocType> => () => async (dispatch, getState) => {
  // must be signed in
  const currentUser = firebase.auth().currentUser
  if (!currentUser) return

  // only establish listeners of not already listening
  if (getState()[docName]?.unsubscribe) return

  // subscribe
  const unsubscribe = firebase
    .firestore()
    .doc(docPath)
    .onSnapshot(snapshot => {
      dispatch({
        type: updateDocActionType,
        data: snapshot.data() as DocType
      })
    })

  dispatch({
    type: updateUnsubscribeFnActionType,
    data: unsubscribe
  })
}
