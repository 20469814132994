export enum updateListActionType {
  updateCategoryList = 'updateCategoryList',
  updateLocationList = 'updateLocationList',
  updateUserList = 'updateUserList',
  updateAssetList = 'updateAssetList',
  updateAssetsMetaList = 'updateAssetsMetaList',
  updateAssetCustodianChangeList = 'updateAssetCustodianChangeList'
}

export enum updateDocActionType {
  updateUser = 'updateUser',
  updateCompany = 'updateCompany'
}

export enum updateLoadingStatusActionType {
  updateCategoryListLoadingStatus = 'updateCategoryListLoadingStatus',
  updateLocationListLoadingStatus = 'updateLocationListLoadingStatus',
  updateUserListLoadingStatus = 'updateUserListLoadingStatus',
  updateAssetListLoadingStatus = 'updateAssetListLoadingStatus',
  updateAssetsMetaListLoadingStatus = 'updateAssetsMetaListLoadingStatus',
  updateAssetCustodianChangeListLoadingStatus = 'updateAssetCustodianChangeListLoadingStatus',
  updateUserLoadingStatus = 'updateUserLoadingStatus',
  updateCompanyLoadingStatus = 'updateCompanyLoadingStatus'
}

export enum updateUnsubscribeFnActionType {
  updateUserUnsubscribeFn = 'updateUserUnsubscribeFn',
  updateCompanyUnsubscribeFn = 'updateCompanyUnsubscribeFn',
  updateCategoryListUnsubscribeFn = 'updateCategoryListUnsubscribeFn',
  updateLocationListUnsubscribeFn = 'updateLocationListUnsubscribeFn',
  updateUserListUnsubscribeFn = 'updateUserListUnsubscribeFn',
  updateAssetListUnsubscribeFn = 'updateAssetListUnsubscribeFn',
  updateAssetsMetaListUnsubscribeFn = 'updateAssetsMetaListUnsubscribeFn',
  updateAssetCustodianChangeListUnsubscribeFn = 'updateAssetCustodianChangeListUnsubscribeFn',
  updateAllAggrateBookValuesUnsubscribeFn = 'updateAllAggrateBookValuesUnsubscribeFn'
}

export enum updateAssetPageExpiredAssetsTablePageActionType {
  updateAllAssetsPageExpiredAssetsTablePage = 'updateExpiredAssetTablePage',
  updateMyAssetsPageExpiredAssetsTablePage = 'updateExpiredAssetTablePage'
}

export enum updateAssetPageActiveAssetsTablePageActionType {
  updateAllAssetsPageActiveAssetsTablePage = 'updateActiveAssetTablePage',
  updateMyAssetsPageActiveAssetsTablePage = 'updateActiveAssetTablePage'
}
