import {
  UpdateAssetPageAction,
  updateAssetPageActiveAssetsTablePageActionType,
  updateAssetPageExpiredAssetsTablePageActionType
} from 'actionTypes'

export interface AssetPageState {
  activeAssetTablePage: number
  expiredAssetTablePage: number
}

const initialState: AssetPageState = {
  activeAssetTablePage: 1,
  expiredAssetTablePage: 1
}

export const assetPageReducerFactory = (
  updateActiveAssetTablePage: updateAssetPageActiveAssetsTablePageActionType,
  updateExpiredAssetTablePage: updateAssetPageExpiredAssetsTablePageActionType
) => (state: AssetPageState = initialState, action: UpdateAssetPageAction) => {
  switch (action.type) {
    case updateActiveAssetTablePage: {
      return { ...state, activeAssetTablePage: action.newPage }
    }
    case updateExpiredAssetTablePage: {
      return { ...state, expiredAssetTablePage: action.newPage }
    }
    default:
      return state
  }
}
