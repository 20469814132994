import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'

async function deleteCategoryDocs(
  companyRef: string,
  categoryRef: string,
  categoryName: string
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create CategoryDoc
  const categoryDocRef = db.doc(
    `companies/${companyRef}/categories/${categoryRef}`
  )
  batch.delete(categoryDocRef)

  const categoryIndexDocRef = db.doc(
    `companies/${companyRef}/categoryIndex/${categoryName}`
  )
  batch.delete(categoryIndexDocRef)

  await batch.commit()
}

export async function deleteCategory(
  companyRef: string,
  categoryRef: string,
  categoryName: string
): Promise<StandardReturn> {
  try {
    await deleteCategoryDocs(companyRef, categoryRef, categoryName)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
