import {
  AssetBookValue,
  AssetDoc,
  AssetMeta,
  getAssetBookValues,
  getAssetYearEndValues
} from '@poem/pam-utils'
import { Month } from '@poem/utils'
import { MiniArea } from 'ant-design-pro/lib/Charts'
import { Alert, Button, InputNumber, Radio, Table } from 'antd'
import { Card, Center, Form, FormProps } from 'components'
import 'firebase/firestore'
import { createAsset, updateAsset } from 'functions'
import { useAssetsMetaListDataItems } from 'hooks/useAssetsMetaListDataItems'
import { useCompanyRef } from 'hooks/useCompanyRef'
import { isEqual } from 'lodash'
import { Moment } from 'moment-timezone'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import { AddEditType, StandardReturn } from 'utils'
import styles from './styles.module.css'

function getOldAssetMeta(
  id: string,
  assetsMetaItems: AssetsMetaListDataItem[]
): AssetMeta {
  for (const item of assetsMetaItems) {
    for (const assetMeta of item.doc.assets) {
      if (id === assetMeta.id) {
        return assetMeta
      }
    }
  }

  throw Error(
    'Invalid input - Cannot find asset with given id in assetsMetaItems'
  )
}

interface GeneralValues {
  id: string
  description: string
  category: string
  location: string
  purchaseDate: Moment
  disposalDate?: Moment
  purchasePrice: number
  scrapValue: number
}

interface DepreciationFormProps {
  type: AddEditType
  generalValues: GeneralValues
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  currentStep: number
  initialValues?: { id: string; yearlyDepreciationPercentages: number[] }
}

export const DepreciationForm = ({
  type: addEditType,
  generalValues,
  currentStep,
  setCurrentStep,
  initialValues = { id: '', yearlyDepreciationPercentages: [20] }
}: DepreciationFormProps) => {
  const assetsMetaItems = useSelector<AppState, AssetsMetaListDataItem[]>(
    state => state.assetsMetaList.data ?? []
  )

  const { purchaseDate, purchasePrice, scrapValue } = generalValues

  const companyRef = useCompanyRef()
  const assetsMetaDocs = useAssetsMetaListDataItems()

  const [type, setType] = useState<'simple' | 'advanced'>(
    initialValues.yearlyDepreciationPercentages.every(
      (val, i, arr) => val === arr[0]
    )
      ? 'simple'
      : 'advanced'
  )
  const [
    simpleDepreciationPercentage,
    setSimpleDepreciationPercentage
  ] = useState(initialValues.yearlyDepreciationPercentages[0])
  const [advDepreciationPercentages, setAdvDepreciationPercentages] = useState<
    number[]
  >(initialValues.yearlyDepreciationPercentages)
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')

  const handleAdvancedSubmit = useCallback(
    async (value?: number[]) => {
      if (!companyRef || loading) return

      const ydps = value ?? advDepreciationPercentages

      setLoading(true)
      setFormError('')

      const purchaseDate =
        generalValues.purchaseDate.date() +
        generalValues.purchaseDate.month() * 100 +
        generalValues.purchaseDate.year() * 10000

      let disposalDate
      if (generalValues.disposalDate) {
        disposalDate =
          generalValues.disposalDate.date() +
          generalValues.disposalDate.month() * 100 +
          generalValues.disposalDate.year() * 10000
      }

      let res: StandardReturn
      if (addEditType === 'add') {
        const assetDoc: AssetDoc = {
          ...generalValues,
          purchaseDate,
          disposalDate,
          assignee: null,
          currentCustodian: null,
          pendingCustodian: null,
          custodianHistory: [],
          images: {},
          yearlyDepreciationPercentages: ydps
        }

        res = await createAsset(companyRef, assetDoc, assetsMetaDocs)
      } else {
        const oldAssetMeta = getOldAssetMeta(initialValues.id, assetsMetaItems)
        const newAssetMeta: AssetMeta = {
          ...generalValues,
          purchaseDate,
          disposalDate,
          assignee: oldAssetMeta.assignee,
          currentCustodian: oldAssetMeta.currentCustodian,
          pendingCustodian: oldAssetMeta.pendingCustodian,
          yearlyDepreciationPercentages: ydps
        }

        res = await updateAsset(
          companyRef,
          initialValues.id,
          newAssetMeta,
          assetsMetaItems
        )
      }

      setLoading(false)

      if (!res.success) {
        setFormError(res.error)
        return
      }

      setCurrentStep(currentStep + 1)
    },
    [
      addEditType,
      advDepreciationPercentages,
      assetsMetaDocs,
      assetsMetaItems,
      companyRef,
      currentStep,
      generalValues,
      initialValues.id,
      loading,
      setCurrentStep
    ]
  )

  const handleSimpleSubmit = useCallback(
    value => {
      const values = getAssetBookValues(
        purchaseDate.date(),
        purchaseDate.month() as Month,
        purchaseDate.year(),
        purchasePrice,
        scrapValue,
        [value.yearlyDepreciationPercentages]
      )

      handleAdvancedSubmit(values.yearlyDepreciationPercentages)
    },
    [handleAdvancedSubmit, purchaseDate, purchasePrice, scrapValue]
  )

  const calculateAdvancedFromSimple = useCallback(() => {
    const values = getAssetBookValues(
      purchaseDate.date(),
      purchaseDate.month() as Month,
      purchaseDate.year(),
      purchasePrice,
      scrapValue,
      [simpleDepreciationPercentage]
    )

    setAdvDepreciationPercentages(values.yearlyDepreciationPercentages)
  }, [purchaseDate, purchasePrice, scrapValue, simpleDepreciationPercentage])

  const values = getAssetBookValues(
    purchaseDate.date(),
    purchaseDate.month() as Month,
    purchaseDate.year(),
    purchasePrice,
    scrapValue,
    type === 'simple'
      ? [simpleDepreciationPercentage]
      : advDepreciationPercentages
  )

  const yearEndAssetBookValues = getAssetYearEndValues(values.assetBookValues)

  const data = values.assetBookValues.map(assetBookValue => ({
    x: `${assetBookValue.day}/${assetBookValue.month + 1}/${
      assetBookValue.year
    }`,
    y: assetBookValue.value
  }))

  return (
    <>
      <div style={{ marginBottom: 64 }}>
        <MiniArea line height={200} data={data} />
      </div>
      <Center>
        <Card title="Depreciation">
          <Radio.Group
            style={{ marginBottom: 16 }}
            defaultValue="simple"
            value={type}
            onChange={e => setType(e.target.value)}
          >
            <Radio.Button value="simple">Simple</Radio.Button>
            <Radio.Button
              value="advanced"
              onClick={calculateAdvancedFromSimple}
            >
              Advanced
            </Radio.Button>
          </Radio.Group>

          {type === 'simple' && (
            <SimpleForm
              handleSubmit={handleSimpleSubmit}
              loading={loading}
              formError={formError}
              onChange={value => setSimpleDepreciationPercentage(Number(value))}
              initialValue={initialValues.yearlyDepreciationPercentages[0]}
            />
          )}
          {type === 'advanced' && (
            <AdvancedForm
              formError={formError}
              loading={loading}
              handleSubmit={() => handleAdvancedSubmit()}
              generalValues={generalValues}
              yearEndAssetBookValues={yearEndAssetBookValues}
              advDepreciationPercentages={advDepreciationPercentages}
              setAdvDepreciationPercentages={setAdvDepreciationPercentages}
            />
          )}
        </Card>
      </Center>
    </>
  )
}

interface SimpleFormProps {
  loading: boolean
  formError: string
  onChange: (value: number | string | undefined) => void
  handleSubmit: (value: any) => void
  initialValue?: number
}

const SimpleForm = ({
  loading,
  formError,
  onChange,
  handleSubmit,
  initialValue = 20
}: SimpleFormProps) => {
  const simpleFormProps: FormProps = {
    layout: 'vertical',
    initialValues: {
      yearlyDepreciationPercentages: initialValue
    },
    items: [
      {
        name: 'yearlyDepreciationPercentages',
        label: 'Yearly Depreciation Percentage',
        rules: [{ required: true }],
        children: (
          <InputNumber
            onChange={onChange}
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => (value ?? '0').replace('%', '')}
          />
        )
      }
    ],
    loading,
    formError,
    onFinish: handleSubmit
  }

  return <Form {...simpleFormProps} />
}

interface AdvancedFormProps {
  loading: boolean
  formError: string
  generalValues: GeneralValues
  yearEndAssetBookValues: AssetBookValue[]
  advDepreciationPercentages: number[]
  setAdvDepreciationPercentages: React.Dispatch<React.SetStateAction<number[]>>
  handleSubmit: () => void
}

const AdvancedForm = ({
  loading,
  formError,
  generalValues,
  yearEndAssetBookValues,
  advDepreciationPercentages,
  setAdvDepreciationPercentages,
  handleSubmit
}: AdvancedFormProps) => {
  const [localPercentages, setLocalPercentages] = useState<number[]>([])
  const oldAdvDepreciationPercentages = useRef<number[]>([])

  useEffect(() => {
    if (
      isEqual(oldAdvDepreciationPercentages.current, advDepreciationPercentages)
    )
      return

    oldAdvDepreciationPercentages.current = [...advDepreciationPercentages]

    const newLocalPercentages = [...localPercentages]
    for (let i = 0; i < advDepreciationPercentages.length; i++) {
      newLocalPercentages[i] = advDepreciationPercentages[i]
    }

    setLocalPercentages(newLocalPercentages)
  }, [advDepreciationPercentages, localPercentages])

  const handleChange = useCallback(
    (value: number, i: number) => {
      const newAdvDepreciationPercentages = [...advDepreciationPercentages]
      newAdvDepreciationPercentages[i] = value
      const { purchaseDate, purchasePrice, scrapValue } = generalValues
      const values = getAssetBookValues(
        purchaseDate.date(),
        purchaseDate.month() as Month,
        purchaseDate.year(),
        purchasePrice,
        scrapValue,
        newAdvDepreciationPercentages
      )

      setAdvDepreciationPercentages(values.yearlyDepreciationPercentages)
    },
    [advDepreciationPercentages, generalValues, setAdvDepreciationPercentages]
  )

  const dataSource = localPercentages.map((value, i) => {
    const scrapValueReachedMessage = 'Scrap value reached in this year'
    let yearEndAssetBookValue = ''
    let enabled = true

    if (typeof yearEndAssetBookValues[i]?.value === 'number') {
      if (yearEndAssetBookValues[i].value === generalValues.scrapValue) {
        yearEndAssetBookValue = scrapValueReachedMessage
      } else {
        yearEndAssetBookValue = String(yearEndAssetBookValues[i].value)
      }
    } else {
      if (
        i === yearEndAssetBookValues.length &&
        yearEndAssetBookValues[i - 1].value !== generalValues.scrapValue
      ) {
        yearEndAssetBookValue = scrapValueReachedMessage
      } else if (i >= yearEndAssetBookValues.length) {
        enabled = false
      }
    }

    return {
      key: i,
      value,
      year: i + 1,
      yearEndAssetBookValue,
      enabled
    }
  })
  const columns = [
    { title: 'Year', dataIndex: 'year' },
    {
      title: 'Yearly Depreciation Percentage',
      dataIndex: 'value',
      render: (value: number, row: any) => (
        <InputNumber
          value={value}
          min={0}
          max={100}
          formatter={value => `${value}%`}
          onChange={value => handleChange(Number(value), row.key)}
          parser={value => (value ?? '0').replace('%', '')}
        />
      )
    },
    {
      title: 'Year-End/Last Asset Book Value',
      dataIndex: 'yearEndAssetBookValue'
    }
  ]

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowClassName={record => (!record.enabled ? styles['disabled-row'] : '')}
      />
      {advDepreciationPercentages.length >= 100 && (
        <>
          <Alert
            type="error"
            message="Scrap value not reached within 100 years"
          />
          <br />
        </>
      )}
      {formError && (
        <>
          <Alert type="error" message={formError} />
          <br />
        </>
      )}
      <Button
        loading={loading}
        disabled={advDepreciationPercentages.length >= 100}
        type="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  )
}
