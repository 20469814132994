import {
  UpdateAssetPageAction,
  updateAssetPageActiveAssetsTablePageActionType,
  updateAssetPageExpiredAssetsTablePageActionType
} from 'actionTypes'

export const updateAssetTablePageFactory = (
  updateAssetPageActionType:
    | updateAssetPageActiveAssetsTablePageActionType
    | updateAssetPageExpiredAssetsTablePageActionType
) => (newPage: number): UpdateAssetPageAction => ({
  type: updateAssetPageActionType,
  newPage
})
