import { AssetMeta } from '@poem/pam-utils'
import { useAssetMetas } from 'hooks'
import { useMemo } from 'react'
import { getAssetMetasMap } from 'utils'

export const useAssetMetasMap = (
  filter?: (assetMeta: AssetMeta) => boolean
) => {
  const assets = useAssetMetas(filter)
  return useMemo(() => getAssetMetasMap(assets), [assets])
}
