import { CategoryDoc, CategoryIndexDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'
import { v4 as uuid } from 'uuid'

export async function isCategoryNameAvailable(
  companyRef: string,
  categoryName: string
) {
  const doc = await firebase
    .firestore()
    .doc(`companies/${companyRef}/categoryIndex/${categoryName}`)
    .get()

  return !doc.exists
}

async function createCategoryDocs(
  companyRef: string,
  categoryDoc: CategoryDoc
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create CategoryDoc
  const categoryRef = uuid()
  const categoryDocRef = db.doc(
    `companies/${companyRef}/categories/${categoryRef}`
  )
  batch.set(categoryDocRef, categoryDoc)

  const categoryIndexDocRef = db.doc(
    `companies/${companyRef}/categoryIndex/${categoryDoc.name}`
  )
  const categoryIndexDoc: CategoryIndexDoc = {
    categoryRef
  }
  batch.set(categoryIndexDocRef, categoryIndexDoc)

  await batch.commit()
}

export async function createCategory(
  companyRef: string,
  categoryDoc: CategoryDoc
): Promise<StandardReturn> {
  const categoryNameAvailable = await isCategoryNameAvailable(
    companyRef,
    categoryDoc.name
  )

  if (!categoryNameAvailable) {
    return { success: false, error: errors.notAvailable('category', 'name') }
  }

  try {
    await createCategoryDocs(companyRef, categoryDoc)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
