import { allAssetPage } from 'reducers/allAssetPage'
import { assetList } from 'reducers/assetList'
import { assetsMetaList } from 'reducers/assetsMetaList'
import { categoryList } from 'reducers/categoryList'
import { company } from 'reducers/company'
import { locationList } from 'reducers/locationList'
import { myAssetPage } from 'reducers/myAssetPage'
import { user } from 'reducers/user'
import { userList } from 'reducers/userList'
import { combineReducers } from 'redux'

export const reducers = combineReducers({
  user,
  company,
  categoryList,
  locationList,
  userList,
  assetList,
  assetsMetaList,
  allAssetPage,
  myAssetPage
})

export * from 'reducers/appState'
export * from 'reducers/assetList'
export * from 'reducers/assetsMetaList'
export * from 'reducers/categoryList'
export * from 'reducers/company'
export * from 'reducers/locationList'
export * from 'reducers/user'
export * from 'reducers/userList'
export * from './factories'
