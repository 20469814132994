import { CategoryDoc, CategoryIndexDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'
import { isCategoryNameAvailable } from './createCategory'

async function updateCategoryDocs(
  companyRef: string,
  categoryRef: string,
  oldCategoryName: string,
  newCategoryDoc: CategoryDoc
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update CategoryDoc
  const categoryDocRef = db.doc(
    `companies/${companyRef}/categories/${categoryRef}`
  )
  batch.set(categoryDocRef, newCategoryDoc)

  if (oldCategoryName !== newCategoryDoc.name) {
    // delete old CategoryIndexDoc
    const oldCategoryIndexDocRef = db.doc(
      `companies/${companyRef}/categoryIndex/${oldCategoryName}`
    )
    batch.delete(oldCategoryIndexDocRef)

    // create new CategoryIndexDoc
    const newCategoryIndexDocRef = db.doc(
      `companies/${companyRef}/categoryIndex/${newCategoryDoc.name}`
    )
    const newCategoryIndexDoc: CategoryIndexDoc = {
      categoryRef
    }
    batch.set(newCategoryIndexDocRef, newCategoryIndexDoc)
  }

  await batch.commit()
}

export async function updateCategory(
  companyRef: string,
  categoryRef: string,
  oldCategoryName: string,
  newCategoryDoc: CategoryDoc
): Promise<StandardReturn> {
  if (oldCategoryName !== newCategoryDoc.name) {
    const categoryNameAvailable = await isCategoryNameAvailable(
      companyRef,
      newCategoryDoc.name
    )

    if (!categoryNameAvailable) {
      return { success: false, error: errors.notAvailable('category', 'name') }
    }
  }

  try {
    await updateCategoryDocs(
      companyRef,
      categoryRef,
      oldCategoryName,
      newCategoryDoc
    )
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
