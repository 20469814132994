import { AssetMeta } from '@poem/pam-utils'
import { printDateNumber } from '@poem/utils'
import { TablePaginationConfig } from 'antd/lib/table'
import { StandardTable } from 'components'
import { useDeleteAssets } from 'hooks/useDeleteAssets'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { assetActionButton } from 'utils'

interface ExpiredAssetsTableProps {
  assets: AssetMeta[]
  selectable?: boolean
  pagination?: TablePaginationConfig
}

export const ExpiredAssetsTable = ({
  assets,
  selectable = false,
  pagination
}: ExpiredAssetsTableProps) => {
  const history = useHistory()
  const handleDelete = useDeleteAssets()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Purchase Date',
      dataIndex: 'purchaseDate',
      render: (value: number | undefined) => printDateNumber(value)
    },
    {
      title: 'Disposal Date',
      dataIndex: 'disposalDate',
      render: (value: number | undefined) => printDateNumber(value)
    }
  ]

  const dataSource = assets.map(asset => ({
    key: asset.id,
    id: asset.id,
    name: asset.description,
    purchaseDate: asset.purchaseDate,
    disposalDate: asset.disposalDate,
    actionButton: asset.id,
    value: asset
  }))

  const tableProps = {
    title: `Disposed Assets (${assets.length})`,
    columns,
    dataSource,
    showExtras: { add: false, edit: false, delete: selectable },
    selectable,
    handleDelete,
    actionButton: { ...assetActionButton(history) },
    pagination
  }

  return <StandardTable {...tableProps} />
}
