import {
  updateAssetPageActiveAssetsTablePageActionType,
  updateAssetPageExpiredAssetsTablePageActionType
} from 'actionTypes'
import { assetPageReducerFactory } from './factories'

export const myAssetPage = assetPageReducerFactory(
  updateAssetPageActiveAssetsTablePageActionType.updateMyAssetsPageActiveAssetsTablePage,
  updateAssetPageExpiredAssetsTablePageActionType.updateMyAssetsPageExpiredAssetsTablePage
)
