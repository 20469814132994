import { CompanyDoc, CompanyIndexDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { errors, StandardReturn } from 'utils'
import { isCompanyNameAvailable } from './register'

async function updateCompanySettingsUpdateDocs(
  companyRef: string,
  newCompanyDoc: CompanyDoc,
  oldCompanyName: string
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update CompanyDoc
  const companyDocRef = db.doc(`companies/${companyRef}`)
  batch.update(companyDocRef, newCompanyDoc)

  if (oldCompanyName !== newCompanyDoc.name) {
    // delete old CompanyIndexDoc
    const oldCompanyDocRef = db.doc(`companyIndex/${oldCompanyName}`)
    batch.delete(oldCompanyDocRef)

    // create new CompanyIndexDoc
    const newCompanyDocRef = db.doc(`companyIndex/${newCompanyDoc.name}`)
    const newCompanyIndexDoc: CompanyIndexDoc = {
      companyRef
    }
    batch.set(newCompanyDocRef, newCompanyIndexDoc)
  }

  await batch.commit()
}

async function uploadLogo(companyRef: string, file: File) {
  const ref = firebase.storage().ref(`companies/${companyRef}/logo.png`)
  await ref.put(file)
  const logoUrl = await ref.getDownloadURL()

  // add url to CompanyDoc
  const db = firebase.firestore()
  const batch = db.batch()
  const companyDocRef = db.doc(`companies/${companyRef}`)
  const companyDocUpdates: Partial<CompanyDoc> = {
    logoUrl
  }
  batch.update(companyDocRef, companyDocUpdates)

  await batch.commit()
}

export async function updateCompanySettings(
  companyRef: string,
  oldCompanyName: string,
  newCompanyDoc: CompanyDoc,
  logoFile?: File | null
): Promise<StandardReturn> {
  if (oldCompanyName !== newCompanyDoc.name) {
    const companyNameAvailable = await isCompanyNameAvailable(
      newCompanyDoc.name
    )
    if (!companyNameAvailable) {
      return { success: false, error: errors.companyNameNotAvailable }
    }
  }

  try {
    await updateCompanySettingsUpdateDocs(
      companyRef,
      newCompanyDoc,
      oldCompanyName
    )
    if (logoFile) {
      await uploadLogo(companyRef, logoFile)
    }
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
