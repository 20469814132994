import { AssetsMetaDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface AssetsMetaListDataItem {
  id: string
  doc: AssetsMetaDoc
}

export interface AssetsMetaList {
  data?: AssetsMetaListDataItem[]
  loading?: boolean
  unsubscribe?: () => void
}

export const assetsMetaList = docAndCollectionReducerFactory<
  AssetsMetaDoc,
  AssetsMetaListDataItem[]
>(
  updateListActionType.updateAssetsMetaList,
  updateUnsubscribeFnActionType.updateAssetsMetaListUnsubscribeFn,
  updateLoadingStatusActionType.updateAssetsMetaListLoadingStatus
)
