import { UserDoc } from '@poem/pam-utils'
import { message } from 'antd'
import { CardSection, Form } from 'components'
import { updateUser } from 'functions'
import { useCompanyRef, useUser } from 'hooks'
import React, { useCallback, useState } from 'react'
import { userFormData } from './user-form-data'

export const UserForm = () => {
  const user = useUser()
  const companyRef = useCompanyRef()
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')

  const handleSubmit = useCallback(
    async (values: any) => {
      if (loading || !companyRef || !user) return

      setLoading(true)
      setFormError('')

      const newUserDoc: UserDoc = {
        ...user,
        name: values.name,
        theme: values.theme
      }

      const res = await updateUser(companyRef, user.email, newUserDoc)

      setLoading(false)

      if (res.success) {
        message.success('Updated successfully')
      } else {
        setFormError(res.error)
      }
    },
    [user, companyRef, loading]
  )

  const initialValue = {
    name: user?.name,
    theme: user?.theme ?? 'light'
  }

  return (
    <CardSection
      title={userFormData.title}
      description={userFormData.description}
      cardProps={{
        title: userFormData.title
      }}
    >
      <Form
        {...userFormData.userFormProps(initialValue)}
        onFinish={handleSubmit}
        loading={loading}
        formError={formError}
      />
    </CardSection>
  )
}
