import {
  AssetCustodianChangeRequest,
  AssetDoc,
  AssetsMetaDoc
} from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import { errors, StandardReturn } from 'utils'
import { batchUpdateAssetsMetaDoc } from './updateAsset'

async function custodianChangeRequestCancelUpdateDocs(
  companyRef: string,
  assetCustodianChangeRequest: AssetCustodianChangeRequest,
  email: string
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update pendingCustodian and assignee in AssetDoc
  const assetDocRef = db.doc(
    `companies/${companyRef}/assets/${assetCustodianChangeRequest.assetRef}`
  )
  const assetDocUpdates: Partial<AssetDoc> = {
    pendingCustodian: null,
    assignee: null
  }
  batch.update(assetDocRef, assetDocUpdates)

  // delete request in UserDoc
  const userDocRef = db.doc(`companies/${companyRef}/users/${email}`)
  const userDocUpdates = {
    requests: firebase.firestore.FieldValue.arrayRemove(
      assetCustodianChangeRequest
    )
  }
  batch.update(userDocRef, userDocUpdates)

  await batch.commit()
}

export async function custodianChangeRequestCancel(
  companyRef: string,
  assetCustodianChangeRequest: AssetCustodianChangeRequest,
  email: string
): Promise<StandardReturn> {
  try {
    await custodianChangeRequestCancelUpdateDocs(
      companyRef,
      assetCustodianChangeRequest,
      email
    )
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}

function getNewAssetsMetaDocs(
  assetsMetaItems: AssetsMetaListDataItem[],
  assetCustodianChangeRequests: AssetCustodianChangeRequest[]
): { doc: AssetsMetaDoc; id: string }[] {
  const requestsMap: { [id: string]: AssetCustodianChangeRequest } = {}

  for (const assetMeta of assetCustodianChangeRequests) {
    requestsMap[assetMeta.id] = assetMeta
  }

  const newAssetsMetaDocs: { doc: AssetsMetaDoc; id: string }[] = []

  for (const item of assetsMetaItems) {
    // find all assets in this item and remove them from doc
    let containAsset = false
    let newAssets = [...item.doc.assets]

    for (let i = 0; i < newAssets.length; i++) {
      const am = newAssets[i]

      if (requestsMap[am.id]) {
        containAsset = true
        newAssets[i] = {
          ...newAssets[i],
          pendingCustodian: null
        }
      }
    }

    if (containAsset) {
      let newAssetsMetaDoc = { assets: newAssets }
      newAssetsMetaDocs.push({ doc: newAssetsMetaDoc, id: item.id })
    }
  }

  return newAssetsMetaDocs
}

export async function custodianChangeRequestsCancel(
  companyRef: string,
  assetsMetaListDataItems: AssetsMetaListDataItem[],
  assetCustodianChangeRequests: AssetCustodianChangeRequest[],
  email: string
): Promise<StandardReturn> {
  const promises = []

  for (const request of assetCustodianChangeRequests) {
    promises.push(
      custodianChangeRequestCancelUpdateDocs(companyRef, request, email)
    )
  }

  const newAssetsMetaDocs = getNewAssetsMetaDocs(
    assetsMetaListDataItems,
    assetCustodianChangeRequests
  )

  for (const newAssetsMetaDoc of newAssetsMetaDocs) {
    const db = firebase.firestore()
    const batch = db.batch()

    batchUpdateAssetsMetaDoc(
      batch,
      companyRef,
      newAssetsMetaDoc.id,
      newAssetsMetaDoc.doc
    )

    promises.push(batch.commit())
  }

  try {
    await Promise.all(promises)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
