import { AssetDoc, getCurrentAssetBookValue } from '@poem/pam-utils'
import { printDateNumber } from '@poem/utils'
import { TableSection } from 'components'
import currencyFormatter from 'currency-formatter'
import { useCompany } from 'hooks'
import React from 'react'

interface GeneralProps {
  loading?: boolean
  asset?: AssetDoc
}

export const General = ({ loading, asset }: GeneralProps) => {
  const company = useCompany()

  const generalColumns = [
    {
      title: 'Property',
      dataIndex: 'property'
    },
    {
      title: 'Value',
      dataIndex: 'value'
    }
  ]

  const generalDataSource = [
    {
      property: 'ID',
      value: asset?.id
    },
    {
      property: 'Name',
      value: asset?.description
    },
    {
      property: 'Category',
      value: asset?.category
    },
    {
      property: 'Location',
      value: asset?.location
    },
    {
      property: 'Book Value',
      value:
        asset && company
          ? currencyFormatter.format(getCurrentAssetBookValue(asset).value, {
              code: company.currency
            })
          : ''
    },
    {
      property: 'Custodian',
      value: asset?.currentCustodian
    },
    {
      property: 'Purchase Date',
      value: asset ? printDateNumber(asset.purchaseDate) : ''
    },
    {
      property: 'Disposal Date',
      value:
        asset && asset.disposalDate ? printDateNumber(asset.disposalDate) : ''
    },
    {
      property: 'Purchase Price',
      value:
        asset && company
          ? currencyFormatter.format(asset.purchasePrice, {
              code: company.currency
            })
          : ''
    },
    {
      property: 'Scrap Value',
      value:
        asset && company
          ? currencyFormatter.format(asset.scrapValue, {
              code: company.currency
            })
          : ''
    },
    {
      property: 'Yearly Depreciation Percentages',
      value: asset ? asset.yearlyDepreciationPercentages.join(', ') : ''
    }
  ]

  return (
    <TableSection
      title="General"
      description="General asset information"
      tableProps={{
        title: 'General',
        loading: loading,
        columns: generalColumns,
        dataSource: generalDataSource,
        showExtras: false,
        selectable: false,
        pagination: { pageSize: 20, hideOnSinglePage: true }
      }}
    />
  )
}
