import { DatePicker, Select } from 'antd'
import { FormProps } from 'components'
import moment from 'moment-timezone'
import React from 'react'

const formProps = (categories: string[], locations: string[]): FormProps => ({
  layout: 'vertical',
  initialValues: {
    date: moment(),
    categories,
    locations
  },
  items: [
    {
      name: 'date',
      label: 'Depreciation period',
      rules: [{ required: true }],
      children: <DatePicker picker="month" />
    },
    {
      name: 'categories',
      label: 'Cagetories',
      children: (
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select some categories"
        >
          {categories
            .sort((a, b) => {
              if (a < b) return -1
              else if (a > b) return 1
              return 0
            })
            .map((category, i) => (
              <Select.Option key={i} value={category}>
                {category}
              </Select.Option>
            ))}
        </Select>
      )
    },
    {
      name: 'locations',
      label: 'Locations',
      children: (
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select some locations"
        >
          {locations
            .sort((a, b) => {
              if (a < b) return -1
              else if (a > b) return 1
              return 0
            })
            .map((location, i) => (
              <Select.Option key={i} value={location}>
                {location}
              </Select.Option>
            ))}
        </Select>
      )
    }
  ]
})

export const data = {
  formProps
}
