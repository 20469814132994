import { UserDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'
import { isUserAlreadyExists, isUserPartOfAnotherCompany } from './addUser'

export interface UserUpdateDoc extends Partial<UserDoc> {
  email: string
}

async function updateUserDocs(
  companyRef: string,
  oldEmail: string,
  newUserDoc: UserUpdateDoc
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update UserDoc
  const userDocRef = db.doc(`companies/${companyRef}/users/${oldEmail}`)
  batch.update(userDocRef, newUserDoc)

  // if (oldEmail !== newUserDoc.email) {
  //   // delete old UserCompanyMapDoc
  //   const oldUserCompanyMapDocRef = db.doc(`userCompanyMap/${oldEmail}`)
  //   batch.delete(oldUserCompanyMapDocRef)

  //   // create new UserCompanyMapDoc = db.doc(
  //   const newUserCompanyMapDocRef = db.doc(`userCompanyMap/${newUserDoc.email}`)
  //   const newUserCompanyMapDoc: UserCompanyMapDoc = {
  //     companyRef
  //   }
  //   batch.set(newUserCompanyMapDocRef, newUserCompanyMapDoc)
  // }

  await batch.commit()
}

export async function updateUser(
  companyRef: string,
  oldEmail: string,
  newUserDoc: UserUpdateDoc
): Promise<StandardReturn> {
  if (oldEmail !== newUserDoc.email) {
    const [userAlreadyExists, userPartOfAnotherCompany] = await Promise.all([
      isUserAlreadyExists(companyRef, newUserDoc.email),
      isUserPartOfAnotherCompany(companyRef, newUserDoc.email)
    ])

    if (userAlreadyExists) {
      return { success: false, error: errors.userAlreadyPartOfYourCompany }
    }

    if (userPartOfAnotherCompany) {
      return { success: false, error: errors.userAlreadyPartOfAnotherCompany }
    }
  }

  try {
    await updateUserDocs(companyRef, oldEmail, newUserDoc)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
