import { AppstoreAddOutlined, UserOutlined } from '@ant-design/icons'
import { UserRole } from '@poem/pam-utils'
import {
  updateAllAssetsPageActiveAssetsTablePage,
  updateAllAssetsPageExpiredAssetsTablePage
} from 'actions'
import { Button } from 'antd'
import { ExpiredAssetsTable, StandardTable } from 'components'
import { useAssetMetas, useCompany, useUser } from 'hooks'
import { useDeleteAssets } from 'hooks/useDeleteAssets'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState, AssetPageState } from 'reducers'
import {
  assetActionButton,
  assetColumns,
  getAssetsDataSource,
  isExpired,
  routes
} from 'utils'

export const AllAssets = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeAssets = useAssetMetas(asset => !isExpired(asset.disposalDate))
  const expiredAssets = useAssetMetas(asset => isExpired(asset.disposalDate))
  const loading = useSelector<AppState, boolean>(state =>
    Boolean(state.assetList.loading)
  )
  const company = useCompany()
  const handleDelete = useDeleteAssets()
  const { activeAssetTablePage, expiredAssetTablePage } = useSelector<
    AppState,
    AssetPageState
  >(state => state.allAssetPage)
  const user = useUser()

  const dataSource = useMemo(() => {
    if (!activeAssets) {
      return []
    }
    return getAssetsDataSource(activeAssets)
  }, [activeAssets])

  return (
    <>
      <StandardTable
        title={`Active Assets (${activeAssets.length})`}
        loading={loading}
        columns={assetColumns(company?.currency ?? '')}
        dataSource={dataSource}
        selectable={user?.role !== UserRole.auditor}
        showExtras={
          user?.role === UserRole.auditor
            ? false
            : {
                add: true,
                delete: true,
                edit: true
              }
        }
        extras={
          user?.role === UserRole.auditor
            ? undefined
            : ({ selectedRows }) => (
                <>
                  <Button
                    onClick={() =>
                      history.push(routes.assignCustodian, { selectedRows })
                    }
                    disabled={selectedRows.length < 1}
                    icon={<UserOutlined />}
                  >
                    Assign Custodian
                  </Button>
                  <Button
                    onClick={() => history.push(routes.bulkAddUpdateAssets)}
                    icon={<AppstoreAddOutlined />}
                  >
                    Bulk Add/Update
                  </Button>
                </>
              )
        }
        addRoute={routes.addAsset}
        editRoute={routes.editAsset}
        handleDelete={handleDelete}
        actionButton={assetActionButton(history)}
        pagination={{
          current: activeAssetTablePage,
          onChange: page =>
            dispatch(updateAllAssetsPageActiveAssetsTablePage(page))
        }}
      />
      <br />
      <ExpiredAssetsTable
        assets={expiredAssets}
        selectable={user?.role !== UserRole.auditor}
        pagination={{
          current: expiredAssetTablePage,
          onChange: page =>
            dispatch(updateAllAssetsPageExpiredAssetsTablePage(page))
        }}
      />
    </>
  )
}
