import {
  AppstoreOutlined,
  BellOutlined,
  BorderOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons'
import { UserRole } from '@poem/pam-utils'
import React from 'react'
import { routes } from 'utils'

export const data = {
  menuItems: [
    {
      title: 'Assets',
      icon: <AppstoreOutlined />,
      subMenu: [
        {
          roles: [UserRole.admin, UserRole.auditor, UserRole.manager],
          title: 'All Assets',
          route: routes.allAssets,
          icon: <AppstoreOutlined />
        },
        {
          roles: [UserRole.admin, UserRole.manager, UserRole.viewer],
          title: 'My Assets',
          route: routes.myAssets,
          icon: <BorderOutlined />
        }
      ]
    },
    {
      roles: [UserRole.admin, UserRole.manager, UserRole.auditor],
      title: 'Depreciation',
      route: routes.depreciation,
      icon: <PieChartOutlined />
    },
    {
      roles: [UserRole.admin, UserRole.manager, UserRole.viewer],
      title: 'Requests',
      route: routes.requests,
      icon: <BellOutlined />
    },
    {
      roles: [UserRole.admin],
      title: 'Settings',
      route: routes.settings,
      icon: <SettingOutlined />
    },
    {
      roles: [
        UserRole.admin,
        UserRole.manager,
        UserRole.auditor,
        UserRole.viewer
      ],
      title: 'Profile',
      route: routes.profile,
      icon: <UserOutlined />
    }
  ]
}
