import {
  AssetMeta,
  getAssetBookValues,
  getMonthDepreciation
} from '@poem/pam-utils'
import {
  getDateFromDateNumber,
  getDaysInMonth,
  Month,
  printDateNumber
} from '@poem/utils'
import { Card, message, Typography } from 'antd'
import { ExpiredAssetsTable, Form, Table } from 'components'
import currencyFormatter from 'currency-formatter'
import { useCompany, useGroups } from 'hooks'
import moment, { Moment } from 'moment-timezone'
import pluralize from 'pluralize'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers'
import { data } from './depreciation-data'

interface AssetRowValues {
  asset: AssetMeta
  currentBookValue: number
  depreciableValue: number
  accumulatedDepreciation: number
  depreciationInThisPeriod: number
}

const getAssetRowValues = (
  asset: AssetMeta,
  month: number,
  year: number
): AssetRowValues => {
  const purchaseDate = getDateFromDateNumber(asset.purchaseDate)
  const bookValues = getAssetBookValues(
    purchaseDate.day,
    purchaseDate.month as Month,
    purchaseDate.year,
    asset.purchasePrice,
    asset.scrapValue,
    asset.yearlyDepreciationPercentages
  )
  const daysInMonth = getDaysInMonth(month as Month, year)
  const currentBookValue =
    bookValues.assetBookValues.find(
      bookValue =>
        bookValue.day === daysInMonth &&
        bookValue.month === month &&
        bookValue.year === year
    )?.value ?? asset.scrapValue
  const prevMonth = month > 0 ? month - 1 : 11
  const prevYear = month > 0 ? year : year - 1
  const prevDaysInMonth = getDaysInMonth(prevMonth as Month, prevYear)
  const prevBookValue =
    bookValues.assetBookValues.find(
      bookValue =>
        bookValue.day === prevDaysInMonth &&
        bookValue.month === prevMonth &&
        bookValue.year === prevYear
    )?.value ?? asset.scrapValue
  const accumulatedDepreciation = asset.purchasePrice - currentBookValue
  const startDay =
    year === purchaseDate.year && month === purchaseDate.month
      ? purchaseDate.day
      : 1
  const monthDepreciation = getMonthDepreciation(
    startDay,
    month as Month,
    year,
    bookValues.yearlyDepreciationPercentages[year - purchaseDate.year] / 100,
    asset.purchasePrice - asset.scrapValue
  )
  let depreciationInThisPeriod = 0
  if (
    !(
      currentBookValue === asset.scrapValue &&
      prevBookValue === asset.scrapValue
    )
  ) {
    if (
      prevBookValue > asset.scrapValue &&
      currentBookValue === asset.scrapValue
    ) {
      depreciationInThisPeriod = prevBookValue - currentBookValue
    } else {
      depreciationInThisPeriod = monthDepreciation
    }
  }
  const depreciableValue = asset.purchasePrice - asset.scrapValue

  return {
    asset,
    currentBookValue,
    depreciableValue,
    accumulatedDepreciation,
    depreciationInThisPeriod
  }
}

const getRow = (assets: AssetMeta[], month: number, year: number) => {
  const assetRow = assets.map(asset => getAssetRowValues(asset, month, year))
  const totalDepreciableValue = assetRow.reduce(
    (p, c) => p + c.depreciableValue,
    0
  )
  const totalAccumulatedDepreciation = assetRow.reduce(
    (p, c) => p + c.accumulatedDepreciation,
    0
  )
  const totalBookValue = assetRow.reduce((p, c) => p + c.currentBookValue, 0)
  const totalDepreciationInThisPeriod = assetRow.reduce(
    (p, c) => p + c.depreciationInThisPeriod,
    0
  )

  return [
    {
      id: 'Total',
      name: '',
      purchaseDate: '',
      depreciableValue: totalDepreciableValue,
      accumulatedDepreciation: totalAccumulatedDepreciation,
      bookValue: totalBookValue,
      depreciationInThisPeriod: totalDepreciationInThisPeriod
    },
    ...assetRow.map(assetRow => getAssetRow(assetRow))
  ]
}

const getAssetRow = (assetRow: AssetRowValues) => {
  const {
    asset,
    depreciableValue,
    accumulatedDepreciation,
    currentBookValue,
    depreciationInThisPeriod
  } = assetRow

  return {
    id: asset.id,
    name: asset.description,
    purchaseDate: asset.purchaseDate,
    disposalDate: asset.disposalDate,
    depreciableValue,
    accumulatedDepreciation,
    bookValue: currentBookValue,
    depreciationInThisPeriod: depreciationInThisPeriod
  }
}

export const Depreciation = () => {
  const company = useCompany()
  const categories = useGroups('category')
  const locations = useGroups('location')
  const loading = useSelector<AppState, boolean>(state =>
    Boolean(state.assetList.loading)
  )

  const [date, setDate] = useState<Moment | null>(moment())
  const [selectedCategories, setSelectedCategories] = useState(categories)
  const [selectedLocations, setSelectedLocations] = useState(locations)

  const handleSubmit = useCallback(
    (values: { date: Moment; categories: string[]; locations: string[] }) => {
      setDate(values.date)
      setSelectedCategories(values.categories)
      setSelectedLocations(values.locations)
      message.info('List updated')
    },
    []
  )

  const assetMetas = useSelector<AppState, AssetMeta[]>(state => {
    if (!state.assetsMetaList.data || !date) return []

    const assets = []
    for (const assetsMetaListDataItem of state.assetsMetaList.data) {
      assets.push(...assetsMetaListDataItem.doc.assets)
    }

    return assets.filter(
      value =>
        selectedCategories.indexOf(value.category) >= 0 &&
        selectedLocations.indexOf(value.location) >= 0 &&
        date.year() * 10000 + (date.month() + 1) * 100 >= value.purchaseDate
    )
  })

  const activeAssets = assetMetas.filter(value => {
    if (!date) {
      return false
    }
    const dateNumber = date.year() * 10000 + date.month() * 100

    if (!value.disposalDate) {
      return true
    }

    return value.disposalDate > dateNumber
  })

  const expiredAssets = assetMetas.filter(value => {
    if (!date) {
      return false
    }
    const dateNumber = date.year() * 10000 + date.month() * 100

    if (!value.disposalDate) {
      return false
    }

    return value.disposalDate <= dateNumber
  })

  const activeColumns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Purchase Date',
      dataIndex: 'purchaseDate',
      render: (value: number | undefined) => printDateNumber(value)
    },
    {
      title: 'Disposal Date',
      dataIndex: 'disposalDate',
      render: (value: number | undefined) => printDateNumber(value)
    },
    {
      title: 'Depreciation value',
      dataIndex: 'depreciableValue',
      render: (value: number) =>
        currencyFormatter.format(value, { code: company?.currency })
    },
    {
      title: 'Accumulated Depreciation',
      dataIndex: 'accumulatedDepreciation',
      render: (value: number) =>
        currencyFormatter.format(value, { code: company?.currency })
    },
    {
      title: 'Book Value',
      dataIndex: 'bookValue',
      render: (value: number) =>
        currencyFormatter.format(value, { code: company?.currency })
    },
    {
      title: 'Depreciation in this period',
      dataIndex: 'depreciationInThisPeriod',
      render: (value: number) =>
        currencyFormatter.format(value, { code: company?.currency })
    }
  ]

  const activeDataSource = getRow(
    activeAssets,
    date?.month() ?? 0,
    date?.year() ?? 0
  )

  return (
    <>
      <Card title="Filters">
        <Form
          {...data.formProps(categories, locations)}
          onFinish={handleSubmit}
        />
      </Card>
      <br />
      <Table
        title={() => (
          <Typography.Title level={5}>
            {`${activeDataSource.length - 1} ${pluralize(
              'asset',
              activeDataSource.length - 1
            )}`}
          </Typography.Title>
        )}
        columns={activeColumns}
        dataSource={activeDataSource}
        loading={loading}
        selectable={false}
      />
      <br />
      <ExpiredAssetsTable assets={expiredAssets} />
    </>
  )
}
