import { CategoryOrLocation } from 'utils'
import { useGroups } from './useGroups'

export const useGroupOptions = (
  type: CategoryOrLocation,
  remove?: string[],
  add?: string
) => {
  const groups = useGroups(type)
  let newGroups = groups

  // remove, add and sort

  if (remove) {
    newGroups = groups.filter(group => !remove.includes(group))
  }

  if (add) {
    newGroups.push(add)
  }

  newGroups.sort()

  const newOptions = newGroups.map(group => ({ value: group, text: group }))
  return newOptions
}
