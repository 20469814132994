import { getSingularPrefix } from '@poem/utils'
import { capitalize } from 'lodash'

// navigation
export enum routes {
  home = '/',
  register = '/register',
  asset = '/dashboard/asset/:id',
  allAssets = '/dashboard/all-assets',
  myAssets = '/dashboard/my-assets',
  addAsset = '/dashboard/add-asset',
  editAsset = '/dashboard/edit-asset',
  depreciation = '/dashboard/depreciation',
  requests = '/dashboard/requests',
  settings = '/dashboard/settings',
  addCategory = '/dashboard/add-category',
  editCategory = '/dashboard/edit-category',
  addLocation = '/dashboard/add-location',
  editLocation = '/dashboard/edit-location',
  addUser = '/dashboard/add-user',
  editUser = '/dashboard/edit-user',
  assignCustodian = '/dashboard/assign-custodian',
  profile = '/dashboard/profile',
  moveAndDeleteCategory = '/dashboard/move-delete-category',
  moveAndDeleteLocation = '/dashboard/move-delete-location',
  bulkAddUpdateAssets = '/dashboard/bulk-add-update-assets'
}

// styles
export const pageMarginTop = 96
export const pageMarginBottom = 96
export enum color {
  primary = '#b28861'
}

export const maxAssetYears = 100

// number of docs per fetch
export const pageLength = 5

// Assets
export const MAX_ASSET_UPDATE_GROUP_SIZE = 249
export const MAX_ASSETMETAS_PER_DOC = 500
export const MAX_DEPRECIATION_PERCENTAGES_LENGTH = 100

// error messages
export const errors = {
  tooLong: 'Too long.',
  cannotEqual: (name: string, values: string) =>
    `${capitalize(name)} cannot equal the following: ${values}.`,
  cannotRegisterAdminAccountAlreadyPartOfAnotherCompany:
    'Cannot create a company with this account because this account is already a part of a different company.',
  notAvailable: (objName: string, attrName: string) =>
    `This ${objName} already exists! Please use a different ${attrName}.`,
  companyNameNotAvailable:
    'This company name is not available. Please use a different name.',
  somethingWentWrong: 'Something went wrong.',
  userAlreadyPartOfAnotherCompany: `Cannot add this user because they're already a part of another company.`,
  userAlreadyPartOfYourCompany: `Cannot add this user because they're already a part of your company.`,
  required: (name: string) =>
    `Please enter ${getSingularPrefix(name)} ${name}.`,
  requiredSelect: (name: string) =>
    `Please select ${getSingularPrefix(name)} ${name}.`,
  alplaNumeric: (name: string) =>
    `Invalid ${name}. Please only enter alphanumeric values.`,
  positive: (name: string) => `${capitalize(name)} must be possitive.`,
  doesNotExists: (name: string) => `${capitalize(name)} does not exists.`,
  invalid: (name: string) => `Invalid ${name}.`,
  assetDepreciateTooLong: `Please set a higher depreciation percentage. Asset must reach scrap value within ${maxAssetYears} years.`,
  assetYearlyDepreciationPercentagesTooLong: `Yealy depreciation percentages must be less than ${MAX_DEPRECIATION_PERCENTAGES_LENGTH} years.`,
  assetYearlyDepreciationPercentageOutOfRange:
    'All yearly depreciation percentages must be more than or equal to 1 and less than or equal 100.',
  assetScrapValueMoreThanPurchasePrice:
    'Scrap value must be less than the purchase price.',
  assetDisposalDateBeforePurchaseDate:
    'Disposal date must be after the purchase date.',
  updatingExpiredAsset: 'Expired asset cannot be updated.',
  maxLengthExceeded: (name: string, length: number) =>
    `${capitalize(name)} must have a length of less than ${length}.`
}

export enum firebaseFunctionName {
  deleteStorageFolder = 'deleteStorageFolder'
}

// form validation
export const MAX_TEXT_FIELD_LENGTH = 100
export const MAX_TEXT_AREA_LENGTH = 1000
