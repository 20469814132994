import firebase from 'firebase/app'
import 'firebase/auth'
import { isPartOfCompany } from 'functions'
import { useCallback, useState } from 'react'

export const useSignin = () => {
  const [loading, setLoading] = useState(false)

  const handleSignin = useCallback(async () => {
    if (loading) return

    setLoading(true)

    const { user } = await firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
    if (!user?.email || !(await isPartOfCompany(user.email))) {
      await user?.delete()
    }
  }, [loading])

  return { handleSignin, loading }
}
