import { CompanyDoc } from '@poem/pam-utils'
import { printDateNumber } from '@poem/utils'
import { Collapse, Typography } from 'antd'
import { StandardTable } from 'components'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import { Asset } from '../bulk-add-update-assets-interfaces'

const columns = [
  {
    title: 'Property',
    dataIndex: 'property'
  },
  {
    title: 'Value',
    dataIndex: 'value'
  }
]

export interface NewAssetsSummaryProps {
  assets: Asset[]
  company?: CompanyDoc
}

export const NewAssetsSummary = ({
  assets,
  company
}: NewAssetsSummaryProps) => (
  <>
    <Typography.Title level={5}>New Assets ({assets.length})</Typography.Title>
    {!assets.length && <p>No new assets</p>}
    <Collapse>
      {assets.map((asset, i) => {
        const dataSource = [
          {
            property: 'ID',
            value: asset.id
          },
          {
            property: 'Name',
            value: asset.description
          },
          {
            property: 'Category',
            value: asset.category
          },
          {
            property: 'Location',
            value: asset.location
          },

          {
            property: 'Purchase Date',
            value: printDateNumber(asset.purchaseDate)
          },
          {
            property: 'Disposal Date',
            value: printDateNumber(asset.disposalDate)
          },
          {
            property: 'Purchase Price',
            value: company
              ? currencyFormatter.format(asset.purchasePrice, {
                  code: company.currency
                })
              : ''
          },
          {
            property: 'Scrap Value',
            value: company
              ? currencyFormatter.format(asset.scrapValue, {
                  code: company.currency
                })
              : ''
          },
          {
            property: 'Yearly Depreciation Percentages',
            value: asset.yearlyDepreciationPercentages.join(', ')
          }
        ]

        return (
          <Collapse.Panel header={`${asset.description} (${asset.id})`} key={i}>
            <StandardTable
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 20, hideOnSinglePage: true }}
              showExtras={false}
              selectable={false}
            />
          </Collapse.Panel>
        )
      })}
    </Collapse>
  </>
)
