import { UserCompanyMapDoc, UserDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'

export async function isUserPartOfAnotherCompany(
  companyRef: string,
  email: string
) {
  const doc = await firebase.firestore().doc(`userCompanyMap/${email}`).get()

  if (!doc.exists) {
    return false
  }

  const userCompanyDoc = doc.data() as UserCompanyMapDoc

  return userCompanyDoc.companyRef !== companyRef
}

export async function isUserAlreadyExists(companyRef: string, email: string) {
  const doc = await firebase.firestore().doc(`userCompanyMap/${email}`).get()

  if (!doc.exists) {
    return false
  }

  const userCompanyDoc = doc.data() as UserCompanyMapDoc

  return userCompanyDoc.companyRef === companyRef
}

async function createUserDocs(companyRef: string, userDoc: UserDoc) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create UserDoc
  const userDocRef = db.doc(`companies/${companyRef}/users/${userDoc.email}`)
  batch.set(userDocRef, userDoc)

  // create userCompanyMapDoc
  const userCompanyMapDocRef = db.doc(`userCompanyMap/${userDoc.email}`)
  const userCompanyMapDoc: UserCompanyMapDoc = {
    companyRef
  }
  batch.set(userCompanyMapDocRef, userCompanyMapDoc)

  await batch.commit()
}

export async function addUser(
  companyRef: string,
  userDoc: UserDoc
): Promise<StandardReturn> {
  try {
    const [partOfAnotherCompany, alreadyExistsInCompany] = await Promise.all([
      isUserPartOfAnotherCompany(companyRef, userDoc.email),
      isUserAlreadyExists(companyRef, userDoc.email)
    ])

    if (partOfAnotherCompany) {
      return { success: false, error: errors.userAlreadyPartOfAnotherCompany }
    }

    if (alreadyExistsInCompany) {
      return { success: false, error: errors.userAlreadyPartOfYourCompany }
    }

    await createUserDocs(companyRef, userDoc)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
