import { InboxOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { DraggerProps } from 'antd/lib/upload/Dragger'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useState } from 'react'

export const FileUpload = (props: DraggerProps) => {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    let newFileList = [...info.fileList]

    // limit the number of uploaded files
    newFileList = newFileList.slice(-1)

    setFileList(newFileList)

    if (props.onChange) {
      const newInfo = { ...info, fileList: newFileList }
      props.onChange(newInfo)
    }
  }

  return (
    <Upload.Dragger
      {...props}
      name="file"
      customRequest={({ onSuccess, file }) => onSuccess({}, file)}
      accept={props.accept ?? 'image/x-png,image/gif,image/jpeg'}
      multiple={props.multiple ?? false}
      showUploadList={{
        showDownloadIcon: false
      }}
      fileList={fileList}
      onChange={handleChange}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Upload.Dragger>
  )
}
