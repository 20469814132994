import { LocationDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface LocationListDataItem {
  id: string
  doc: LocationDoc
}

export interface LocationList {
  data?: LocationListDataItem[]
  loading?: boolean
  unsubscribe?: () => void
}

export const locationList = docAndCollectionReducerFactory<
  LocationDoc,
  LocationListDataItem
>(
  updateListActionType.updateLocationList,
  updateUnsubscribeFnActionType.updateLocationListUnsubscribeFn,
  updateLoadingStatusActionType.updateLocationListLoadingStatus
)
