import { UserDoc, UserRole } from '@poem/pam-utils'
import {
  subscribeToCategoryList,
  subscribeToCompany,
  subscribeToLocationList,
  subscribeToUser,
  subscribeToUserList,
  unsubscribeFromCategoryList,
  unsubscribeFromCompany,
  unsubscribeFromLocationList,
  unsubscribeFromUser,
  unsubscribeFromUserList
} from 'actions'
import { subscribeToAssetsMetaList } from 'actions/subscribeToAssetsMetaList'
import { unsubscribeFromAssetsMetaList } from 'actions/unsubscribeFromAssetsMetaList'
import 'App.css'
import firebase from 'firebase/app'
import 'firebase/auth'
import { AuthLayout, DashboardLayout } from 'layouts'
import { pagesData } from 'pages-data'
import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import { AppState } from 'reducers/appState'
import { routes } from 'utils'

const renderRoutes = (user?: UserDoc) => {
  return pagesData
    .filter(item => item.roles.includes(user?.role ?? 'all'))
    .map((item, i) => (
      <Route key={i} path={item.path} exact component={item.component} />
    ))
}

interface LayoutProps {
  user?: UserDoc
  children: ReactNode
}

const Layout = ({ user, children }: LayoutProps) => {
  const role = user?.role ?? 'all'

  if (role === 'all') {
    return <AuthLayout>{children}</AuthLayout>
  } else {
    return <DashboardLayout>{children}</DashboardLayout>
  }
}

export const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector<AppState, UserDoc | undefined>(
    state => state.user.data
  )
  const prevRole = useRef(null)

  const { switcher } = useThemeSwitcher()

  useEffect(() => {
    if (user?.theme) {
      switcher({ theme: user.theme })
    }

    if (prevRole.current !== user?.role && user?.role === UserRole.auditor) {
      history.push(routes.allAssets)
    }
  }, [history, switcher, user])

  const subscribe = useCallback(() => {
    dispatch(subscribeToUser())
    dispatch(subscribeToCompany())
    dispatch(subscribeToUserList())
    dispatch(subscribeToLocationList())
    dispatch(subscribeToCategoryList())
    dispatch(subscribeToAssetsMetaList())
  }, [dispatch])

  const unsubscribe = useCallback(() => {
    dispatch(unsubscribeFromCompany())
    dispatch(unsubscribeFromUser())
    dispatch(unsubscribeFromUserList())
    dispatch(unsubscribeFromLocationList())
    dispatch(unsubscribeFromCategoryList())
    dispatch(unsubscribeFromAssetsMetaList())
  }, [dispatch])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        subscribe()
        history.push(routes.myAssets)
      } else {
        unsubscribe()
        history.push(routes.home)
      }
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route>
      <Switch>
        <Layout user={user}>{renderRoutes(user)}</Layout>
      </Switch>
    </Route>
  )
}

export default App
