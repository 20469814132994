import React from 'react'
import {
  CategoryLocationList,
  CompanySettingsForm,
  UserList
} from './components'

export const Settings = () => {
  return (
    <>
      <CompanySettingsForm />
      <CategoryLocationList type="category" />
      <CategoryLocationList type="location" />
      <UserList />
    </>
  )
}
