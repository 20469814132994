import { CompanyDoc } from '@poem/pam-utils'
import {
  UpdateCompanyAction,
  UpdateCompanyUnsubscribeFnAction,
  updateDocActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/auth'
import 'firebase/firestore'
import { AppState } from 'reducers/appState'
import { ThunkAction } from 'redux-thunk'
import { subscribeToDocFactory } from './factories'
import { getCompanyRef } from './utils'

type SubscribeToCompany = () => ThunkAction<
  void,
  AppState,
  void,
  UpdateCompanyAction | UpdateCompanyUnsubscribeFnAction
>

export const subscribeToCompany: SubscribeToCompany = () => async (
  dispatch,
  getState
) => {
  const companyRef = await getCompanyRef(getState)

  if (!companyRef) return

  const subscribe = subscribeToDocFactory<CompanyDoc>(
    'company',
    `companies/${companyRef}`,
    updateDocActionType.updateCompany,
    updateUnsubscribeFnActionType.updateCompanyUnsubscribeFn
  )

  dispatch(subscribe())
}
