import { CategoryDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateLoadingStatusActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { docAndCollectionReducerFactory } from './factories'

export interface CategoryListDataItem {
  id: string
  doc: CategoryDoc
}

export interface CategoryList {
  data?: CategoryListDataItem[]
  loading?: boolean
  unsubscribe?: () => void
}

export const categoryList = docAndCollectionReducerFactory<
  CategoryDoc,
  CategoryListDataItem[]
>(
  updateListActionType.updateCategoryList,
  updateUnsubscribeFnActionType.updateCategoryListUnsubscribeFn,
  updateLoadingStatusActionType.updateCategoryListLoadingStatus
)
