import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { errors, StandardReturn } from 'utils'

async function deleteAssetPicUpdateDocs(
  companyRef: string,
  assetRef: string,
  timestamp: number
) {
  const db = firebase.firestore()
  const batch = db.batch()

  const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
  const assetDocUpdatePath = `images.${timestamp}`
  const assetDocUpdates = {
    [assetDocUpdatePath]: firebase.firestore.FieldValue.delete()
  }

  batch.update(assetDocRef, assetDocUpdates)

  await batch.commit()
}

async function deleteAssetPicFromStorage(
  companyRef: string,
  assetRef: string,
  timestamp: number
) {
  const ref = firebase
    .storage()
    .ref(`companies/${companyRef}/assets/${assetRef}/${timestamp}.png`)
  await ref.delete()
}

export async function deleteAssetPic(
  companyRef: string,
  assetRef: string,
  timestamp: number
): Promise<StandardReturn> {
  try {
    await deleteAssetPicUpdateDocs(companyRef, assetRef, timestamp)
    await deleteAssetPicFromStorage(companyRef, assetRef, timestamp)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
