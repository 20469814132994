import { AssetMeta } from '@poem/pam-utils'
import 'firebase/firestore'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import { errors, StandardReturn } from 'utils'
import { updateAssets } from './updateAsset'

// async function assignCustodianDocs(
//   companyRef: string,
//   assetRef: string,
//   pendingCustodian: string,
//   assignee: string
// ) {
//   const db = firebase.firestore()
//   const batch = db.batch()

//   // update pendingCustodian and assignee in AssetDoc
//   const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
//   const assetDocUpdates: Partial<AssetDoc> = {
//     pendingCustodian,
//     assignee
//   }

//   batch.update(assetDocRef, assetDocUpdates)

//   await batch.commit()
// }

export async function assignCustodian(
  companyRef: string,
  affectedAssetMetas: AssetMeta[],
  assetsMetaItems: AssetsMetaListDataItem[],
  pendingCustodian: string,
  assignee: string
): Promise<StandardReturn> {
  try {
    const newAssetMetas: AssetMeta[] = []
    for (const assetMeta of affectedAssetMetas) {
      const newAssetMeta: AssetMeta = {
        ...assetMeta,
        pendingCustodian,
        assignee
      }
      newAssetMetas.push(newAssetMeta)
    }

    await updateAssets(companyRef, newAssetMetas, assetsMetaItems)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
