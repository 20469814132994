import { AssetMeta } from '@poem/pam-utils'
import { deleteAssets } from 'functions'
import { useCallback } from 'react'
import { errors, StandardReturn } from 'utils'
import { useAssetsMetaListDataItems } from './useAssetsMetaListDataItems'
import { useCompanyRef } from './useCompanyRef'

export const useDeleteAssets = () => {
  const companyRef = useCompanyRef()
  const assetsMetaListDataItems = useAssetsMetaListDataItems()

  const handleDelete = useCallback(
    async ({
      selectedRows
    }: {
      selectedRows: any[]
    }): Promise<StandardReturn> => {
      const failedRes = { success: false, error: errors.somethingWentWrong }

      if (!companyRef) return failedRes

      const assetMetas = selectedRows.map(row => row.value as AssetMeta)

      return await deleteAssets(companyRef, assetMetas, assetsMetaListDataItems)
    },
    [assetsMetaListDataItems, companyRef]
  )

  return handleDelete
}
