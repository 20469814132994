import { AssetMeta } from '@poem/pam-utils'
import { Input } from 'antd'
import { Act, FormProps, FormStep, MultiStepFormProps } from 'components'
import {
  createCategory,
  createLocation,
  isCategoryNameAvailable,
  isLocationNameAvailable
} from 'functions'
import { capitalize } from 'lodash'
import React from 'react'
import { CategoryListDataItem, LocationListDataItem } from 'reducers'
import {
  CategoryOrLocation,
  getSuccessStep,
  moveFormProps,
  Option,
  routes
} from 'utils'

const formProps = (
  type: CategoryOrLocation,
  companyRef: string
): FormProps => ({
  layout: 'vertical',
  items: [
    {
      name: 'name',
      label: 'Name',
      rules: [
        { required: true },
        {
          validator: async (rule, value) => {
            if (!value) return

            let available
            if (type === 'category') {
              available = await isCategoryNameAvailable(companyRef, value)
            } else {
              available = await isLocationNameAvailable(companyRef, value)
            }

            if (available) {
              return
            }

            throw Error(`This ${type} name is already taken.`)
          }
        }
      ],
      children: <Input data-test="name-input" />
    }
  ]
})

export const addCategoryLocationData = (
  type: CategoryOrLocation,
  companyRef: string
): MultiStepFormProps => ({
  title: `Create a new ${capitalize(type)}`,
  description: `Please fill in the required information to create a ${type}.`,
  steps: [
    {
      type: 'form',
      title: capitalize(type),
      description: `Create a new ${type}`,
      formProps: formProps(type, companyRef),
      act: async (values, i) => {
        const doc = { ...values }

        let res
        if (type === 'category') {
          res = await createCategory(companyRef, doc)
        } else {
          res = await createLocation(companyRef, doc)
        }

        return { res, goToStep: i + 1 }
      }
    },
    getSuccessStep(type, 'created', routes.settings, 'Settings')
  ]
})

export const editCategoryLocationData = ({
  dataType,
  companyRef,
  oldDoc,
  affectedAssets,
  options,
  handleGroupNameUpdate,
  handleNewGroupNameSubmit,
  handleMoveAndUpdate
}: {
  dataType: CategoryOrLocation
  companyRef: string
  oldDoc: CategoryListDataItem | LocationListDataItem
  affectedAssets: AssetMeta[]
  options: Option[]
  handleGroupNameUpdate: Act
  handleNewGroupNameSubmit: Act
  handleMoveAndUpdate: Act
}): MultiStepFormProps => ({
  title: `Edit ${capitalize(dataType)}`,
  description: `Please fill in the required information to update.`,
  steps: [
    {
      type: 'form',
      title: capitalize(dataType),
      description: `Update ${dataType}`,
      formProps: {
        ...((addCategoryLocationData(dataType, companyRef).steps[0] as FormStep)
          .formProps as FormProps),
        initialValues: { name: oldDoc.doc.name }
      },
      act: handleNewGroupNameSubmit
    },
    {
      type: 'form',
      title: `Move asset's ${dataType}`,
      description: `If there are affected assets, move them of the old ${dataType} to a new one before creating the new ${dataType}`,
      formProps: moveFormProps(dataType, affectedAssets, options),
      act: handleMoveAndUpdate
    },
    getSuccessStep(dataType, 'updated', routes.settings, 'Settings')
  ]
})
