import firebase from 'firebase/app'
import 'firebase/firestore'
import { errors, StandardReturn } from 'utils'

async function updateAssetPicHiddenStatusUpdateDocs(
  companyRef: string,
  assetRef: string,
  timestamp: number,
  hidden: boolean
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // update hidden status
  const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
  const assetDocUpdatePath = `images.${timestamp}.hidden`
  const assetDocUpdates = {
    [assetDocUpdatePath]: hidden
  }
  batch.update(assetDocRef, assetDocUpdates)

  await batch.commit()
}

export async function updateAssetPicHiddenStatus(
  companyRef: string,
  assetRef: string,
  timestamp: number,
  hidden: boolean
): Promise<StandardReturn> {
  try {
    await updateAssetPicHiddenStatusUpdateDocs(
      companyRef,
      assetRef,
      timestamp,
      hidden
    )
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}
