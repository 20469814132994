import { AssetMeta } from '@poem/pam-utils'
import { useAssetsMetaListDataItems } from './useAssetsMetaListDataItems'

export const useAssetMetas = (filter?: (assetMeta: AssetMeta) => boolean) => {
  const assetsMetaListDataItems = useAssetsMetaListDataItems()
  const allAssetMetas = assetsMetaListDataItems
    .map(item => item.doc.assets)
    .flat()

  if (filter) {
    return allAssetMetas.filter(filter)
  }

  return allAssetMetas
}
