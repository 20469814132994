import { UserRole } from '@poem/pam-utils'
import { useAssetsMetaListDataItems, useCompanyRef, useUsers } from 'hooks'
import { AddEditPage } from 'pages'
import React from 'react'
import { assignCustodianData } from './assign-custodian-data'

interface AssignCustodianProps {
  location: any
}

export const AssignCustodian = ({ location }: AssignCustodianProps) => {
  const companyRef = useCompanyRef()
  const users = useUsers()
  const assetsMetaListDataItems = useAssetsMetaListDataItems()

  const nonAuditorUsers = users.filter(
    user => user.doc.role !== UserRole.auditor
  )

  return (
    <AddEditPage
      {...assignCustodianData(
        nonAuditorUsers,
        companyRef,
        location.state.selectedRows.map((row: any) => row.value),
        assetsMetaListDataItems
      )}
    />
  )
}
