import { UserDoc, UserRole } from '@poem/pam-utils'
import { Input, Select } from 'antd'
import { FormProps, FormStep, MultiStepFormProps } from 'components'
import firebase from 'firebase/app'
import 'firebase/auth'
import { addUser, updateUser } from 'functions'
import { capitalize } from 'lodash'
import React from 'react'
import { enumToArray, getSuccessStep, routes } from 'utils'

const formProps = (companyRef: string): FormProps => ({
  layout: 'vertical',
  items: [
    {
      name: 'email',
      label: 'Email',
      rules: [{ required: true }, { type: 'email' }],
      children: <Input />
    },
    {
      name: 'name',
      label: 'Name',
      children: <Input />
    },
    {
      name: 'role',
      label: 'Role',
      rules: [{ required: true }],
      children: (
        <Select>
          {enumToArray(UserRole).map((role, i) => (
            <Select.Option key={i} value={role}>
              {capitalize(role)}
            </Select.Option>
          ))}
        </Select>
      )
    }
  ]
})

export const addUserData = (companyRef: string): MultiStepFormProps => ({
  title: 'Add User',
  description:
    'Please fill in the required information to add a new user to your company.',
  steps: [
    {
      type: 'form',
      title: 'User',
      description: 'Add a new user',
      formProps: formProps(companyRef),
      act: async (values, i) => {
        const doc: UserDoc = { ...values, companyRef, requests: [] }

        const res = await addUser(companyRef, doc)
        return { res, goToStep: i + 1 }
      }
    },
    getSuccessStep('created', 'User', routes.settings, 'Settings')
  ]
})

export const editUserData = (
  companyRef: string,
  oldDoc: UserDoc
): MultiStepFormProps => ({
  title: 'Edit User',
  description: 'Please fill in the required information to update.',
  steps: [
    {
      type: 'form',
      title: 'User',
      description: 'Update user',
      formProps: {
        ...((addUserData(companyRef).steps[0] as FormStep)
          .formProps as FormProps),
        initialValues: {
          ...oldDoc
        },
        items: [
          {
            name: 'name',
            label: 'Name',
            children: <Input />
          },
          {
            name: 'role',
            label: 'Role',
            rules: [{ required: true }],
            children: (
              <Select
                disabled={firebase.auth().currentUser?.email === oldDoc.email}
              >
                {enumToArray(UserRole).map((role, i) => (
                  <Select.Option key={i} value={role}>
                    {capitalize(role)}
                  </Select.Option>
                ))}
              </Select>
            )
          }
        ]
      },
      act: async (values, i) => {
        const doc = { ...values }

        const res = await updateUser(companyRef, oldDoc.email, doc)
        return { res, goToStep: i + 1 }
      }
    },
    getSuccessStep('user', 'created', routes.settings, 'Settings')
  ]
})
