import {
  AssetDoc,
  AssetIndexDoc,
  AssetMeta,
  AssetsMetaDoc
} from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { AssetsMetaListDataItem } from 'reducers/assetsMetaList'
import {
  errors,
  getAssetMetasMap,
  getAssetUpdateGroups,
  MAX_ASSETMETAS_PER_DOC,
  StandardReturn
} from 'utils'
import { v4 as uuid } from 'uuid'

export async function isAssetAlreadyExists(
  companyRef: string,
  assetId: string
) {
  const doc = await firebase
    .firestore()
    .doc(`companies/${companyRef}/assetIndex/${assetId}`)
    .get()
  return doc.exists
}

async function createAssetDocs(
  companyRef: string,
  assetDoc: AssetDoc,
  assetsMetaDocs: AssetsMetaListDataItem[]
) {
  const db = firebase.firestore()
  const batch = db.batch()

  // create AssetDoc
  const assetRef = uuid()
  const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
  batch.set(assetDocRef, assetDoc)

  // create AssetIndexDoc
  const assetIndexDocRef = db.doc(
    `companies/${companyRef}/assetIndex/${assetDoc.id}`
  )
  const assetIndexDoc: AssetIndexDoc = {
    assetRef
  }
  batch.set(assetIndexDocRef, assetIndexDoc)

  // update/create assetsMetaDoc
  const { shouldCreate, newAssetsMetaDoc, id } = getNewAssetsMetaDoc(
    assetsMetaDocs,
    assetDoc
  )
  const assetsMetaRef = db.doc(`companies/${companyRef}/assetsMeta/${id}`)

  if (shouldCreate) {
    batch.set(assetsMetaRef, newAssetsMetaDoc)
  } else {
    batch.update(assetsMetaRef, newAssetsMetaDoc)
  }

  await batch.commit()
}

function getNewAssetsMetaDoc(
  assetsMetaDocs: AssetsMetaListDataItem[],
  assetDoc: AssetDoc
): {
  shouldCreate: boolean
  newAssetsMetaDoc: AssetsMetaDoc
  id: string
} {
  // search for a document with less than MAX_ASSETMETAS_PER_DOC and
  // insert the assetMeta there

  const notFullAssetsMetaDoc = assetsMetaDocs.find(
    value => value.doc.assets.length < MAX_ASSETMETAS_PER_DOC
  )

  const shouldCreate = !Boolean(notFullAssetsMetaDoc)

  const newAssets = notFullAssetsMetaDoc
    ? [...notFullAssetsMetaDoc.doc.assets]
    : []
  const newAssetMeta: AssetMeta = {
    assignee: assetDoc.assignee,
    category: assetDoc.category,
    currentCustodian: assetDoc.currentCustodian,
    description: assetDoc.description,
    id: assetDoc.id,
    location: assetDoc.location,
    pendingCustodian: assetDoc.pendingCustodian,
    purchaseDate: assetDoc.purchaseDate,
    purchasePrice: assetDoc.purchasePrice,
    scrapValue: assetDoc.scrapValue,
    yearlyDepreciationPercentages: assetDoc.yearlyDepreciationPercentages,
    disposalDate: assetDoc.disposalDate
  }

  newAssets.push(newAssetMeta)

  const newAssetsMetaDoc: AssetsMetaDoc = {
    assets: newAssets
  }
  let id = notFullAssetsMetaDoc?.id ?? ''

  if (shouldCreate) {
    id = uuid()
  }

  return {
    shouldCreate,
    newAssetsMetaDoc,
    id
  }
}

export async function createAsset(
  companyRef: string,
  assetDoc: AssetDoc,
  assetsMetaDocs: AssetsMetaListDataItem[]
): Promise<StandardReturn> {
  const assetAlreadyExists = await isAssetAlreadyExists(companyRef, assetDoc.id)

  if (assetAlreadyExists) {
    return { success: false, error: errors.notAvailable('asset', 'id') }
  }

  try {
    await createAssetDocs(companyRef, assetDoc, assetsMetaDocs)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}

/**
 * Bulk create asset. Asset must not already exists (this function will not check this)
 * @param companyRef
 * @param assetMetas
 * @param assetsMetaListDataItems
 */
export async function createAssets(
  companyRef: string,
  assetMetas: AssetMeta[],
  assetsMetaListDataItems: AssetsMetaListDataItem[]
) {
  try {
    await createAssetsDocs(companyRef, assetMetas, assetsMetaListDataItems)
  } catch (error) {
    console.error(error)
    return { success: false, error: errors.somethingWentWrong }
  }

  return { success: true }
}

function getNewAssetsMetaDocs(
  assetsMetaListDataItems: AssetsMetaListDataItem[],
  assetMetas: AssetMeta[]
) {
  // get all docs that are not full
  const nonFullDocs = assetsMetaListDataItems.filter(
    v => v.doc.assets.length < MAX_ASSETMETAS_PER_DOC
  )
  const newDocs: AssetsMetaDoc[] = []
  let nonFullDocIndex = 0
  let newDocIndex = 0

  // add new assets to docs or create new doc if all of them are full
  for (let i = 0; i < assetMetas.length; i++) {
    const assetMeta = assetMetas[i]

    if (!nonFullDocs[nonFullDocIndex] || !nonFullDocs.length) {
      // no more non full asset docs, create a new asset doc
      if (!newDocs[newDocIndex]) {
        // create a new doc
        newDocs.push({
          assets: []
        })
      }

      newDocs[newDocIndex].assets.push(assetMeta)

      if (newDocs[newDocIndex].assets.length >= MAX_ASSETMETAS_PER_DOC) {
        newDocIndex++
      }

      continue
    }

    nonFullDocs[nonFullDocIndex].doc.assets.push(assetMeta)

    if (
      nonFullDocs[nonFullDocIndex].doc.assets.length >=
        MAX_ASSETMETAS_PER_DOC &&
      i !== assetMetas.length - 1
    ) {
      nonFullDocIndex++
    }
  }

  return {
    newAssetsMetaDocs: newDocs,
    updates: nonFullDocs.slice(0, nonFullDocIndex + 1)
  }
}

async function createAssetsDocs(
  companyRef: string,
  assetMetas: AssetMeta[],
  assetsMetaListDataItems: AssetsMetaListDataItem[]
) {
  const { newAssetsMetaDocs, updates } = getNewAssetsMetaDocs(
    assetsMetaListDataItems,
    assetMetas
  )

  for (const newAssetsMetaDoc of newAssetsMetaDocs) {
    const assetGroups = getAssetUpdateGroups(newAssetsMetaDoc.assets)
    await createAssetsInFirestore(companyRef, assetGroups, [])
  }

  const newAssetMetasMap = getAssetMetasMap(assetMetas)

  for (const update of updates) {
    const newAssets = update.doc.assets.filter(
      asset => newAssetMetasMap[asset.id]
    )
    const initialAssets = update.doc.assets.filter(
      asset => !newAssetMetasMap[asset.id]
    )
    const assetGroups = getAssetUpdateGroups(newAssets)

    await createAssetsInFirestore(
      companyRef,
      assetGroups,
      initialAssets,
      update.id
    )
  }
}

/**
 * Create assetDocs, assetIndexDocs and set assetMetasDoc in the same batch
 * @param assetGroups groups of assets to create (must be less than 249)
 * @param initialAssets initial assets inside of the assetMetasDoc
 */
async function createAssetsInFirestore(
  companyRef: string,
  assetGroups: AssetMeta[][],
  initialAssets: AssetMeta[],
  assetMetasDocId?: string
) {
  const db = firebase.firestore()
  const assets = [...initialAssets]
  const id = assetMetasDocId ?? uuid()

  for (const assetGroup of assetGroups) {
    assets.push(...assetGroup)

    const assetsMetaDoc: AssetsMetaDoc = {
      assets
    }

    // set assetsMetaDoc
    const batch = db.batch()
    const assetsMetaDocRef = db.doc(`companies/${companyRef}/assetsMeta/${id}`)
    batch.set(assetsMetaDocRef, assetsMetaDoc)

    for (const asset of assetGroup) {
      // create assetDoc
      const assetDoc: AssetDoc = {
        ...asset,
        custodianHistory: [],
        images: {}
      }
      const assetRef = uuid()
      const assetDocRef = db.doc(`companies/${companyRef}/assets/${assetRef}`)
      batch.set(assetDocRef, assetDoc)

      // create assetIndexDoc
      const assetIndexDoc: AssetIndexDoc = { assetRef }
      const assetIndexDocRef = db.doc(
        `companies/${companyRef}/assetIndex/${assetDoc.id}`
      )
      batch.set(assetIndexDocRef, assetIndexDoc)
    }

    // commit changes
    await batch.commit()
  }
}
