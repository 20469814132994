import { commonCurrencies } from '@poem/pam-utils'
import { Input, Select } from 'antd'
import { FileUpload, FormProps } from 'components'
import { isCompanyNameAvailable } from 'functions'
import React from 'react'

const companySettingsFormProps = (initialValues: any): FormProps => ({
  layout: 'vertical',
  items: [
    {
      name: 'name',
      label: 'Company Name',
      hasFeedback: true,
      rules: [
        {
          required: true
        },
        {
          validator: async (rule, value) => {
            if (!value || value === initialValues.name) return

            const available = await isCompanyNameAvailable(value)
            if (available) {
              return
            }

            throw Error('This company name is already taken.')
          }
        }
      ],
      children: <Input />
    },
    {
      name: 'currency',
      label: 'Currency',
      children: (
        <Select disabled>
          {Object.keys(commonCurrencies).map((code, i) => (
            <Select.Option
              key={i}
              value={code}
            >{`${commonCurrencies[code].name} (${code})`}</Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: 'logo',
      label: 'Logo',
      children: <FileUpload />
    }
  ],
  initialValues
})

export const data = {
  title: 'Company Settings',
  description: (
    <p>
      To update your company settings, please fill in the required information
      then click "Submit"
    </p>
  ),
  companySettingsFormProps
}
