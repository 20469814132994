import { CompanyDoc } from '@poem/pam-utils'
import { Form as AntForm, message } from 'antd'
import { CardSection, Form } from 'components'
import { updateCompanySettings } from 'functions'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers'
import { data } from './company-settings-data'

export interface CompanySettingsFormData {
  name: string
  timezone: string
  currency: string
}

export const CompanySettingsForm = () => {
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [form] = AntForm.useForm()

  const company = useSelector<AppState, CompanyDoc | undefined>(
    state => state.company.data
  )
  const companyRef = useSelector<AppState, string | undefined>(
    state => state.user.data?.companyRef
  )

  const handleSubmit = useCallback(
    async (values: any) => {
      if (loading || !companyRef) return

      setLoading(true)
      setFormError('')

      const newCompanyDoc: CompanyDoc = {
        name: values.name,
        currency: values.currency,
        timezone: 'Asia/Bangkok',
        dateAndTimeFormat: 'dd/MM/yyyy',
        logoUrl: company?.logoUrl ?? ''
      }

      const res = await updateCompanySettings(
        companyRef as string,
        (company as CompanyDoc).name,
        newCompanyDoc,
        values.logo?.file?.originFileObj
      )

      setLoading(false)

      if (res.success) {
        message.success('Company settings updated successfully')
        form.resetFields()
      } else {
        setFormError(res.error)
      }
    },
    [company, companyRef, form, loading]
  )

  return (
    <CardSection
      title={data.title}
      description={data.description}
      cardProps={{
        title: data.title
      }}
    >
      <Form
        {...data.companySettingsFormProps(company)}
        form={form}
        onFinish={handleSubmit}
        loading={loading}
        formError={formError}
      />
    </CardSection>
  )
}
