import { UserCompanyMapDoc } from '@poem/pam-utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { AppState } from 'reducers/appState'

export const getCompanyRef = async (getState: () => AppState) => {
  let companyRef = getState().user?.data?.companyRef

  if (!companyRef) {
    const { currentUser } = firebase.auth()
    if (!currentUser) return
    const userCompanyMapDoc = await firebase
      .firestore()
      .doc(`userCompanyMap/${currentUser.email}`)
      .get()
    if (!userCompanyMapDoc.exists) return

    companyRef = (userCompanyMapDoc.data() as UserCompanyMapDoc).companyRef
  }

  return companyRef
}
