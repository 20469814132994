import { AssetDoc, getAssetBookValues } from '@poem/pam-utils'
import { getDateFromDateNumber, Month } from '@poem/utils'
import { MiniArea } from 'ant-design-pro/lib/Charts'
import { Section } from 'components'
import React from 'react'

interface BookValues {
  asset?: AssetDoc
}

export const BookValues = ({ asset }: BookValues) => {
  let data: { x: string; y: number }[] = []

  if (asset) {
    const { day, month, year } = getDateFromDateNumber(asset.purchaseDate)
    const { assetBookValues } = getAssetBookValues(
      day,
      month as Month,
      year,
      asset.purchasePrice,
      asset.scrapValue,
      asset.yearlyDepreciationPercentages,
      asset.disposalDate
    )

    data = assetBookValues.map(assetBookValue => ({
      x: `${assetBookValue.day}/${assetBookValue.month + 1}/${
        assetBookValue.year
      }`,
      y: assetBookValue.value
    }))
  }

  return (
    <Section
      title="Book Values"
      description={
        <>
          <div>
            The graph shows the asset's book values across it's lifetime.
          </div>
          <div>
            If the disposal date is not defined, the asset retains the final
            value after the last point on the graph. If the disposal date is
            defined, the book value is zero after the last point on the graph.
          </div>
        </>
      }
    >
      <div style={{ marginBottom: 64 }}>
        <MiniArea line height={200} data={data} />
      </div>
    </Section>
  )
}
