import { Input, Select } from 'antd'
import { FormProps } from 'components'
import React from 'react'
import { MAX_TEXT_FIELD_LENGTH } from 'utils'

const userFormProps = (initialValues: any): FormProps => ({
  layout: 'vertical',
  items: [
    {
      name: 'name',
      label: 'Name',
      rules: [{ max: MAX_TEXT_FIELD_LENGTH }],
      children: <Input />
    },
    {
      name: 'theme',
      label: 'Default Theme',
      children: (
        <Select>
          <Select.Option value="light">Light</Select.Option>
          <Select.Option value="dark">Dark</Select.Option>
        </Select>
      )
    }
  ],
  initialValues
})

export const userFormData = {
  title: 'User Settings',
  description:
    'To update your user settings, please fill in the required information and click "Submit"',
  userFormProps
}
