import { AssetDoc, AssetMeta, getCurrentAssetBookValue } from '@poem/pam-utils'
import currencyFormatter from 'currency-formatter'
import { MAX_ASSET_UPDATE_GROUP_SIZE } from './const'

export const assetColumns = (currency: string) => [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Category',
    dataIndex: 'category'
  },
  {
    title: 'Location',
    dataIndex: 'location'
  },
  {
    title: 'Book Value',
    dataIndex: 'bookValue',
    render: (value: number) =>
      currencyFormatter.format(value, { code: currency })
  },
  {
    title: 'Custodian',
    dataIndex: 'custodian'
  }
]

export const assetActionButton = (history: any) => ({
  text: 'View',
  onClick: (id: string) => history.push(`/dashboard/asset/${id}`)
})

export const getAssetCustodianText = (assetMeta: AssetMeta) => {
  let text = ''

  if (assetMeta.currentCustodian) {
    text += `${assetMeta.currentCustodian} `
  }

  if (assetMeta.pendingCustodian) {
    if (assetMeta.currentCustodian) {
      text += `(current), `
    }

    text += `${assetMeta.pendingCustodian} (pending)`
  }

  return text
}

export const getAssetsDataSource = (assetMetas: AssetMeta[]) =>
  assetMetas
    .map(asset => {
      return {
        key: asset.id,
        id: asset.id,
        name: asset.description,
        category: asset.category,
        location: asset.location,
        bookValue: getCurrentAssetBookValue(asset).value,
        custodian: getAssetCustodianText(asset),
        value: asset,
        actionButton: asset.id
      }
    })
    .sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

export const getAssetMetaFromAssetDoc = (assetDoc: AssetDoc): AssetMeta => ({
  assignee: assetDoc.assignee,
  category: assetDoc.category,
  currentCustodian: assetDoc.currentCustodian,
  description: assetDoc.description,
  id: assetDoc.id,
  location: assetDoc.location,
  pendingCustodian: assetDoc.pendingCustodian,
  purchaseDate: assetDoc.purchaseDate,
  purchasePrice: assetDoc.purchasePrice,
  scrapValue: assetDoc.scrapValue,
  yearlyDepreciationPercentages: assetDoc.yearlyDepreciationPercentages,
  disposalDate: assetDoc.disposalDate
})

export const getAssetMetasMap = (assets: AssetMeta[]) => {
  const map: { [id: string]: AssetMeta } = {}

  for (const asset of assets) {
    map[asset.id] = asset
  }

  return map
}

export const getAssetUpdateGroups = (assets: AssetMeta[]) => {
  const assetGroups: AssetMeta[][] = []

  for (let i = 0; i < assets.length; i += MAX_ASSET_UPDATE_GROUP_SIZE) {
    assetGroups.push(assets.slice(i, i + MAX_ASSET_UPDATE_GROUP_SIZE))
  }

  return assetGroups
}
