import { AssetDoc } from '@poem/pam-utils'
import {
  updateListActionType,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import 'firebase/firestore'
import { subscribeToListFactory } from './factories'

export const subscribeToAssetsMetaList = subscribeToListFactory<AssetDoc>(
  'assetsMetaList',
  'assetsMeta',
  updateListActionType.updateAssetsMetaList,
  updateUnsubscribeFnActionType.updateAssetsMetaListUnsubscribeFn
)
