import {
  UpdateDocAction,
  updateDocActionType,
  UpdateListAction,
  updateListActionType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateLoadingStatusActionType,
  UpdateUnsubscribeFnAction,
  updateUnsubscribeFnActionType
} from 'actionTypes'
import { UpdateLoadingStatusAction } from 'actionTypes/mainActionDefinitions/updateLoadingStatusAction'

export interface State<ItemData> {
  data?: ItemData
  loading?: boolean
  unsubscribe?: () => void
}

export const docAndCollectionReducerFactory = <DocType, ItemData>(
  updateDataActionType: updateListActionType | updateDocActionType,
  updateUnsubFnActionType: updateUnsubscribeFnActionType,
  updateLoadingStatusActionType: updateLoadingStatusActionType
) => (
  state: State<ItemData> = {},
  action:
    | UpdateListAction<updateListActionType, DocType>
    | UpdateDocAction<updateDocActionType, DocType>
    | UpdateUnsubscribeFnAction<updateUnsubscribeFnActionType>
    | UpdateLoadingStatusAction<updateLoadingStatusActionType>
) => {
  switch (action.type) {
    case updateUnsubFnActionType: {
      if (action.data === null) {
        // clear data when you unsubscribe

        return {
          data: null,
          loading: false,
          unsubscribe: action.data
        }
      }

      return {
        ...state,
        unsubscribe: action.data
      }
    }
    case updateDataActionType: {
      return {
        ...state,
        data: action.data
      }
    }
    case updateLoadingStatusActionType: {
      return { ...state, loading: action.status }
    }
    default:
      return state
  }
}
