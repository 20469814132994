import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons'
import { CompanyDoc } from '@poem/pam-utils'
import { BackTop, Image, Layout, Menu, Space, Typography } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { Base, Footer } from 'components'
import firebase from 'firebase/app'
import 'firebase/auth'
import { History } from 'history'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppState } from 'reducers/appState'
import { LayoutComponentProps, routes } from 'utils'
import { data } from './dashboard-layout-data'
import styles from './styles.module.css'

const renderMenu = (
  userRole: string,
  item: {
    roles: string[]
    title: string
    route: routes
    icon: JSX.Element
  },
  history: History
) => {
  if (item.roles.includes(userRole)) {
    return (
      <Menu.Item
        data-test={`menu-${item.title.toLowerCase().split(' ').join('-')}-btn`}
        key={item.route}
        onClick={() => item.route && history.push(item.route)}
        icon={item.icon}
      >
        {item.title}
      </Menu.Item>
    )
  } else {
    return null
  }
}

const { Header, Content, Sider } = Layout

export const DashboardLayout = ({ children }: LayoutComponentProps) => {
  const history = useHistory()
  const location = useLocation()

  const company = useSelector<AppState, CompanyDoc | undefined>(
    state => state.company.data
  )
  const userRole = useSelector<AppState, string>(
    state => state.user.data?.role ?? 'all'
  )

  let currentSelected
  for (const value of Object.values(routes)) {
    if (location.pathname.includes(value)) currentSelected = value
  }

  const [collapsed, setCollapse] = useState(false)
  const toggle = useCallback(() => {
    setCollapse(!collapsed)
  }, [collapsed])

  const [openKeys, setOpenKeys] = useState(['Assets'])

  const toggleOpenKeys = useCallback(
    (e: { key: string; domEvent: Event }) => {
      const { key } = e
      const newOpenKeys = [...openKeys]
      const index = openKeys.indexOf(key)

      if (index > -1) {
        newOpenKeys.splice(index, 1)
      } else {
        newOpenKeys.push(key)
      }

      setOpenKeys(newOpenKeys)
    },
    [openKeys]
  )

  return (
    <Layout>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'sticky',
          top: 0,
          left: 0
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className={styles.logo} />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[String(currentSelected)]}
          openKeys={openKeys}
        >
          {data.menuItems.map(item =>
            item.subMenu ? (
              <SubMenu
                key={item.title}
                onTitleClick={toggleOpenKeys}
                icon={item.icon}
                title={item.title}
              >
                {item.subMenu.map(subMenuItem =>
                  renderMenu(userRole, subMenuItem, history)
                )}
              </SubMenu>
            ) : (
              renderMenu(userRole, item, history)
            )
          )}
          <Menu.Item
            className={styles.signout}
            onClick={() => firebase.auth().signOut()}
            icon={<LogoutOutlined />}
          >
            Signout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className={styles['site-layout']}>
        <Header
          className={styles['site-layout-background']}
          style={{ padding: 0 }}
        >
          <Space align="baseline">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: styles.trigger,
                onClick: toggle
              }
            )}
            <Typography.Title className={styles['company-title']} level={5}>
              {company?.name}
            </Typography.Title>
          </Space>
          <div className={styles.company}>
            <Space>
              <Image src={company?.logoUrl} width={48} height={48} />
            </Space>
          </div>
        </Header>
        <Content>
          <Base style={{ marginTop: 20 }}>
            <BackTop />
            {children}
          </Base>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  )
}
