import { useSelector } from 'react-redux'
import { AppState } from 'reducers/appState'
import { CategoryOrLocation } from 'utils'

export const useGroups = (type: CategoryOrLocation) => {
  const groups = useSelector<AppState, string[]>(state => {
    const groupItems =
      state[type === 'category' ? 'categoryList' : 'locationList'].data ?? []
    return groupItems.map(item => item.doc.name)
  })

  return groups
}
