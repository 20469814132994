import { GoogleOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import { CardSection, Link } from 'components'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'utils'
import { data } from './signin-data'
import { useSignin } from './useSignin'

export const Signin = () => {
  const history = useHistory()
  const { handleSignin, loading } = useSignin()

  return (
    <CardSection
      title={data.title}
      description={data.description}
      cardProps={{ title: 'Signin' }}
    >
      <Button
        data-test="continue-with-google-btn"
        onClick={handleSignin}
        loading={loading}
      >
        <GoogleOutlined /> Continue with Google
      </Button>
      <Divider />
      <Link
        data-test="register-link"
        onClick={() => history.push(routes.register)}
      >
        Register a new company
      </Link>
    </CardSection>
  )
}
